import fetchServer from "../fetchServer";

export default async function autoRebook(data,cancelToken) {
  let result = {
    return: 0,
    msg: "Something went wrong fetching booking!",
  };

  let props = {}
  if(cancelToken)
    props.cancelToken = cancelToken

  await fetchServer({
    method: "PATCH",
    url: `/product/v1/book/autoRebook?populate=flightBooking.orderId`,
    data,
    ...props
  })
    .then((res) => {
      if (res) {
        if (res.status === 200) {
          result = { return: 1, msg: "Successfull", data: res.data.data || [] };
        } 
        else if(res?.data?.code === 69)
          result = { return:0, msg: 'Price Changed', data: res?.data?.detail};
        else if (res?.data?.error) {
          result["msg"] = res.data.error
        };
      }
    })
    .catch((err) => {
      console.log("Network Error!");
    });

  return result;
}
