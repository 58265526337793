import Icon from "../../../../components/HOC/Icon";
import { useEffect, useState } from "react";
import moment from "moment";
import { FlightAmenities } from "../../../../components/flight/FlightInfoCard";

export default function TicketFlightInfo({ minify, data, collapse: defCollapse }) {
  const [formattedOrder, setFormattedOrder] = useState();

  console.log(data)
  useEffect(() => {
    if ((data?.orderDetail?.offers || data?.booking?.flightBooking) && !formattedOrder) {
      const segments = 
        (data?.orderDetail?.pricing?.offers &&
        Array.isArray(data?.orderDetail?.pricing?.offers) &&
        data?.orderDetail?.pricing?.offers?.flatMap((offer) =>
          offer.directions.flat(),
        )) || data?.booking?.flightBooking?.at(0)?.flights;

      const originalSegments =
        data?.orderDetail?.pricing?.offers &&
        Array.isArray(data?.orderDetail?.pricing?.offers) &&
        data?.orderDetail?.pricing?.offers
          ?.flatMap((offer) => offer.destinations)
          .slice();
      const destinations = originalSegments?.at(0);

      const formatted = segments.map((segment) => ({
        ...segment,
        airlinePnr: data?.booking?.flightBooking?.at(0)?.travelers?.at(0)?.airlinePnr,
        pnr: data?.booking?.flightBooking?.at(0)?.pnr,
        from: segment?.departure?.location || segment?.departureLocationName,
        to: segment?.arrival?.location || segment?.arrivalLocationName,
        origin: destinations?.from || segment?.departureLocation,
        destination: destinations?.to || segment?.arrivalLocation,
        airlineCode: segment?.airlineCode || segment?.airline,
        airline: segment?.airline?.image?.description || segment?.airlineName,
        airlineIcon: segment?.airline?.image?.url || segment?.airlineImage,
        departureDate: segment?.departure?.date || segment?.departureDate,
        departureTime: segment?.departure?.time || segment?.departureTime,
        ArrivalDate: segment?.arrival?.date || segment?.arrivalDate,
        arrivalTime: segment?.arrival?.time || segment?.arrivalTime,
        departureAirport: segment?.departureAirport || segment?.departureLocation,
        arrivalAirport: segment?.arrivalAirport || segment?.arrivalLocation,
        duration: moment
          .duration(
            moment(
              `${segment?.arrival?.date || segment?.arrivalDate} ${segment?.arrival?.time || segment?.arrivalTime}`,
            ).diff(
              moment(
                `${segment?.departure?.date || segment?.departureDate} ${segment?.departure?.time || segment.departureTime}`,
              ),
              "minutes",
            ),
            "minutes",
          )
          .humanize() || segment?.duration,
        stops: segment?.numberOfStops || segment?.stops,
      }));
      setFormattedOrder(formatted);
    }
  }, [data, formattedOrder]);

  console.log(formattedOrder)

  const layOverTime = []

  function getLayOverTime(date,time,index) {
    try {
      const dateTime = moment(`${date} ${time}`)
      const nextDateTime = formattedOrder[index+1]?.departureDate + ' ' + formattedOrder[index+1]?.arrivalTime;
      
      const duration = moment.duration(dateTime?.diff(moment(nextDateTime)))
      
      return duration.humanize();
      
    } catch(ex) {console.log(ex)}
    return ''
  }

  return (
    <>
      {formattedOrder?.map((formatted, index) => (
        <Displayer key={index} {...{defCollapse,layOverTime,getLayOverTime,minify,index,formatted,formattedOrder}} />
      ))}
    </>
  );
}

function Displayer({defCollapse,getLayOverTime,minify,index,formatted,formattedOrder}) {
  const [collapse,setCollapse] = useState(minify || defCollapse);

  const baggageAllowance = formatted?.baggageAllowance !== 'undefined' ? formatted?.baggageAllowance : '0PC'

  return (
    <div className="flex flex-col gap-4 bg-secondary p-4 border">
          <div className="flex gap-4 items-center" onClick={() => !minify && setCollapse(!collapse)}>
            {/* <div className="flex flex-col gap-2 items-center">
              <img src={formatted.airlineIcon} alt="" className="w-16 h-16" />
              <small className="font-bold min-w-[130px] text-center">{formatted.airline}</small>
            </div> */}
            <div className="flex flex-1 gap-4 items-center flex-wrap sm:flex-nowrap">
              <div className="flex flex-1 justify-start items-center gap-4 flex-wrap md:flex-nowrap">
                <div className="flex gap-1 items-center max-w-[300px]">
                  <div>
                    <b className='!inline uppercase text-xl'>{formatted?.departureAirport}</b>
                    {/* <b>{formatted.from}</b> */}
                    &nbsp;
                    <span className="inline">({formatted?.origin})</span>
                    <div className="py-2">
                      {formatted?.departureDate} {formatted?.departureTime} (Local Time)
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center flex-1 text-center">
                  <div>
                    <div className="p-4 py-1 border-primary/50">
                      {formatted?.airlineCode}{formatted?.flightNumber}
                    </div>
                    <hr className="w-full  border-primary/50" />
                  </div>
                </div>
                <div className="flex gap-1 items-center max-w-[300px]">
                  <div>
                    <b className='!inline uppercase text-xl'>{formatted?.arrivalAirport}</b>
                    {/* <b>{formatted.to}</b> */}
                    &nbsp;
                    <span className="inline">({formatted?.destination})</span>
                    <div className="py-2">
                      {formatted?.arrivalDate} {formatted?.arrivalTime} (Local Time)
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="flex gap-2 flex-wrap max-w-[400px]">
                <span className="text-theme1">
                  ({formatted?.duration})
                </span>
              </div> */}
            </div>
            <div className={"flex flex-col gap-1 border-dashed border-l pl-4 border-primary "+(collapse?'hidden':'')}>
              <div className="flex justify-between items-center gap-4 flex-wrap">
                <div className="flex flex-col gap-1 text-primary/80 max-w-full overflow-x-auto whitespace-nowrap pb-2">
                  {/* <span>{formatted?.airline}</span> */}
                  <span className="flex gap-2"><p>Booking Class:</p> {formatted?.bookingClass}</span>
                  <span><p>Seat Class:</p> {formatted?.cabinClass}</span>
                  <span><p>Airline PNR:</p> {formatted?.airlinePnr || '-'}</span>
                  {/* <span>{formatted?.aircraftType}</span>
                  <span>{formatted?.aircraftCode}</span> */}
                  <span className="flex gap-2"><p>Bag: </p> {baggageAllowance}</span>
                </div>

                {/* <div className="bg-primary/10 rounded-md px-4 py-2 flex gap-4 text-theme1">
                  <FlightAmenities flight={formattedOrder} />
                </div> */}
              </div>
            </div>
            {/* {!minify ? 
              <Icon icon='gridicons:dropdown' />
            :null} */}
          </div>

          {/* {!minify && !collapse ? (
            <div className="flex flex-col gap-0">
              <div className="flex gap-4 items-center ">
                <span className="w-3 h-3 rounded-full bg-theme1"></span>
                <div>{formatted?.departureDate}</div>
                <span>Departing from {formatted?.departureAirport}</span>
              </div>
              <div className="flex gap-4 items-center h-14 -my-2">
                <div className="vr translate-x-[4.5px] w-3 h-full"></div>
                <span className="text-xs flex gap-1 items-center"><Icon icon='mdi:clock' className='!w-3 !h-3' /> {formatted?.duration}</span>
              </div>
              <div className="flex gap-4 items-center ">
                <span className="w-3 h-3 rounded-full bg-theme1"></span>
                <div>{formatted?.ArrivalDate}</div>
                <span>Arriving at {formatted?.arrivalAirport}</span>
              </div>
            </div>
          ) : null} */}

        </div>
  )
}