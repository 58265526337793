import React from "react";

function Loader({ loading, className }) {
  if (!loading) {
    return <></>;
  }
  return (
    <div className={`w-full flex items-center justify-center ${className}`}>
      <div className="loader"></div>
    </div>
  );
}

export default Loader;
