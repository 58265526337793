import React from 'react'
import Transactions from '../Orders/Transactions'

export default function TransactionsPage() {
  return (
    <div className='px-10'>
      <Transactions loadAll={true} />
    </div>
  )
}
