export function getAgeType(name) {
    if(name === 'ADT')
      return 'adult'
    else if(name === 'CNN')
      return 'child'
    else if(name === 'INF')
      return 'infant'
    
    return name
}
