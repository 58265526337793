import { formatMoney } from "../../../features/utils/formatMoney";
import Icon from "../../HOC/Icon";

export const PriceBreakdown = ({data}) => {
    const breakdown = {
      total: "606.17 USD",
      detail: [
        {
          quantity: 1,
          feeName: "Adult",
          total: "606.17 USD",
          items: [
            { name: "Fare per adult", price: "534.1 USD" },
            { name: "Taxes per adult", price: "71.07 USD" },
          ],
        },
        { quantity: 1, feeName: "Ticketing fee", total: "1 USD" },
      ],
    };
  
    const fee = data?.fee || 0;
    
    return (
      <div className="w-full bg-[#F1F1F1] flex flex-col p-4 mt-4">
        <div className="flex justify-end text-primary1 text-end mb-2 text-sm lg:text-base">
          Price breakdown
        </div>
        <div>
            <div className="flex items-center gap-2 font-bold justify-between text-sm lg:text-base">
                <span className="">
                    Change Fee
                </span>
                <span className=" flex gap-2 items-center">{formatMoney(fee)}
                    <Icon icon='tabler:edit' className='text-primary/70 !w-5 !h-5' />
                </span>
            </div>
            <div className="flex items-center gap-2 justify-between !text-sm py-2">
                <p className="pl-4">
                    Service Charge
                </p>
                <p className="">{formatMoney(fee)}</p>
            </div>
        </div>
        {/* {breakdown.detail.map((item, index) => (
          <div key={index} className="flex flex-col mb-2">
            <div className="flex items-center gap-2 font-bold justify-between text-sm lg:text-base">
              <span className="">
                {item.quantity} x {item.feeName}
              </span>
              <span className="">{item.total}</span>
            </div>
            {item.items &&
              item.items?.map((subItem, subIndex) => (
                <div
                  key={subIndex}
                  className="flex items-center gap-2 justify-between text-gray-600 ml-2"
                >
                  <span className="">{subItem.name}</span>
                  <span className="">{subItem.price}</span>
                </div>
              ))}
          </div>
        ))} */}
        <div className="w-full border-b border-gray-300 mb-2"></div>
        <div className="flex items-center gap-2 font-bold justify-between text-sm lg:text-base">
            <span className="">Total</span>
            <div className="flex font-bold w-full justify-end text-end text-primary1 text-sm lg:text-base mt-2">
            {formatMoney(-data?.payable)}
            </div>
        </div>
        {/* <div className="flex font-bold w-full justify-end text-end text-[#6D7C94] text-sm lg:text-base mt-2">
          NOT including payment fee
        </div> */}
      </div>
    );
  };
  