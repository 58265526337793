import { Check } from "@mui/icons-material";
import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CalendarInput1 from "../../../components/form/CalendarInput1";
import TicketManagementHeader from "../../../components/TicketManagement/TicketManagementHeader";
import TicketManagementPassengerSelection from "../../../components/TicketManagement/TicketManagementPassengerSelection";
import TicketManagementUploadDocument from "../../../components/TicketManagement/TicketManagementUploadDocument";
import TicketManagementContactDetails from "../../../components/TicketManagement/TicketManagementContactDetails";
import TicketManagementAddRemark from "../../../components/TicketManagement/TicketManagementAddRemark";
import TicketManagementFlightRefundStepsHeader from "../../../components/TicketManagement/TicketManagementFlightRefundStepsHeader";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmFlightSubmitRefundRequestModal } from "../../../redux/reducers/modalSlice";
import ConfirmFlightSubmitRefundRequestModal from "../../../components/modal/ConfirmFlightSubmitRefundRequestModal";
import TicketManagementLoadingModal from "../../../components/modal/TicketManagementLoadingModal";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import refundTicket from "../../../controllers/booking/postTicketing/refund/refundTicket";
import getBooking from "../../../controllers/booking/getBooking";
import Modal1 from "../../../components/DIsplay/Modal/Modal1";
import { formatMoney } from "../../../features/utils/formatMoney";
import Button1 from "../../../components/form/Button1";
import confirmRefundFee from "../../../controllers/booking/postTicketing/refund/confirmRefundFee";
import { SelectReason } from "./SelectReason";

function TicketManagementFlightRefund() {
  const {id} = useParams()
  const [order, setOrder] = useState();
  const [loading,setLoading] = useState({submit: false, fetching: true, confirmFee: false});
  const [openConfirm,setOpenConfirm] = useState(false)
  const [tickets,setTickets] = useState([])


  const {user} = useSelector(state => state.user.userData);
  const userName = (user?.firstName||'') + ' ' + (user?.middleName||'') + ' ' + (user?.lastName||'');

  const [contact,setContact] = useState({
    name: userName || '',
    email: user?.email || '',
    phone: user?.phone || '',    
  })
  const [reason,setReason] = useState('');
  const [remark,setRemark] = useState('');
  const [documents,setDocuments] = useState([]);
  const [confirmed,setConfirmed] = useState(false);


  const navigate = useNavigate();
  
  useEffect(() => {
    fetch();
    //eslint-disable-next-line
  }, [id]);

  const fetch = async () => {
    if(!id) return setLoading({...loading,fetching: false});

    setLoading({...loading,fetching: true});
    const res = await getBooking(id);
    setLoading({...loading,fetching: false});
    if (res.return) {
      setOrder(res.data);
    }
  };

  async function handleSubmit() {
    // return console.log(tickets);

    setLoading({...loading,submit: true})
    const formData = new FormData();
    const payload = {
      flightBookingId: order?.booking?.flightBooking?.at(0)?._id,
      // passengerIds: tickets?.map(obj => obj?.id),
      contactName: contact?.name,
      contactEmail: contact?.email,
      contactPhone: contact?.phone,
      remark,
      refundType: reason === 'Voluntary' ? 'Voluntary' : 'Involuntary',
      reason: reason === 'Voluntary' ? 'Personal' : reason,
      confirmed,
      documents,
    }
    Object.entries(payload)?.map(([key,val]) => {
      if(key === 'documents')
        val.forEach((file, index) => {
          formData.append(key, file);
        })
      else
        formData.append(key,val)

      return null;
    })
    // return console.log(formData)
    const res = await refundTicket(formData);
    setLoading({...loading,submit: false})
    if(res.return) {
      enqueueSnackbar('Refund ticket request has been received.',{variant: 'success'})
      navigate('/order/flight/ticket/detail/'+(res?.data?._id || id)+'?page=Refund Management')
      // callback && callback()
    } else enqueueSnackbar(res.msg,{variant: 'error'})

  }

  return (
    <div className="flex flex-1 flex-col w-full bg-[#F8F8F8] py-4">
      <ConfirmFlightSubmitRefundRequestModal {...{open: openConfirm,setOpen: setOpenConfirm, confirmed, setConfirmed}} callback={handleSubmit} />
      <TicketManagementLoadingModal />

      <TicketManagementHeader />
      <TicketManagementFlightRefundStepsHeader step={1} />
      <TicketManagementPassengerSelection order={order} callback={(passengers) => setTickets(passengers)} />
      <SelectReason callback={setReason}/>
      <TicketManagementUploadDocument callback={(obj) => setDocuments(obj?.map(oj => oj?.file))} />
      <TicketManagementAddRemark callback={setRemark} />
      <TicketManagementContactDetails order={order} data={contact} callback={(obj) => setContact({...contact,...obj})} />
      <TicketActionButtons />

      
    </div>
  );
}

const TicketActionButtons = () => {
  const dispatch = useDispatch();
  const handleCancel = () => {};

  const handleSubmit = () => {
    dispatch(setConfirmFlightSubmitRefundRequestModal(true));
  };
  return (
    <div className="flex justify-between gap-4 mb-20 mt-10 px-2">
      <button
        onClick={handleCancel}
        className="px-3 py-3 font-bold w-[30%] max-w-[200px] bg-gray-100 rounded-md"
      >
        Cancel
      </button>
      <button
        onClick={handleSubmit}
        className="flex flex-1 text-sm font-bold text-white bg-primary1 rounded-md py-3 items-center justify-center max-w-[250px]"
      >
        Submit refund request
      </button>
    </div>
  );
};

export default TicketManagementFlightRefund;

const Classname = {
  detailInfoContainer:
    "w-full bg-blue-500/10 flex items-start lg:items-center gap-2 mb-4 px-2 lg:px-6 py-2 rounded-md text-sm mt-6",
  detailInfoIcon: "",
  cardContainer:
    "flex flex-1 flex-col w-full p-2 lg:p-4 bg-white mt-6 rounded-md",
  input: "flex flex-1 py-3 rounded-md px-4 border-2 border-gray-200",
};
