import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  adultsNo: 1,
  childrenNo: 0,
  rooms: [{ adults: 1, children: [], infants: 0 }],
  reservedRooms: [],
};

export const dataSlice = createSlice({
  name: "dataSlice",
  initialState,
  reducers: {
    setAdultsNo: (state, action) => {
      state.adultsNo = action.payload;
    },
    setChildrenNo: (state, action) => {
      state.childrenNo = action.payload;
    },
    setRooms: (state, action) => {
      state.rooms = action.payload;
    },
    setReservedRooms: (state, action) => {
      state.reservedRooms = action.payload;
    },
  },
});

export const { setAdultsNo, setChildrenNo, setRooms, setReservedRooms } =
  dataSlice.actions;

export default dataSlice.reducer;
