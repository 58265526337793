import { createSlice } from "@reduxjs/toolkit";

let defaultData = {
  filterData: {
    date: null,
    country: null,
    day: null,
    price: null,
    time: null,
    duration: null,
    destination: null,
    language: [],
    interest: null,
    service: [],
  },
  filterStayData: {
    date: null,
    startDate: null,
    endDate: null,
    latitude: null,
    longitude: null,
    location: null,
    propertyName: null,
    property: null,
    price: null,
    popular: null,
    rating: null,
    day: null,
    payment: null,
    amenities: [],
    moreAmenities: false,
  },
  tourFilterActive: false,
  stayFilterActive: false,
};
let initialState = {
  ...defaultData,
};

export const filterSlice = createSlice({
  name: "tourFilter",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filterData = action.payload;
    },
    setStaysFilter: (state, action) => {
      state.filterStayData = action.payload;
    },
    setTourFilterActive: (state, action) => {
      state.tourFilterActive = action.payload;
    },
    setStayFilterActive: (state, action) => {
      state.stayFilterActive = action.payload;
    },
    clearFilter: (state, action) => {
      state.filterData = {
        day: null,
        price: null,
        time: null,
        duration: null,
        destination: null,
        language: null,
        interest: null,
        service: null,
      };
    },
    clearFilterStays: (state, action) => {
      state.filterStayData = {
        propertyName: null,
        property: null,
        price: null,
        popular: null,
        rating: null,
        day: null,
        payment: null,
        Amenities: null,
      };
    },
  },
});

export const {
  setFilter,
  clearFilter,
  setStaysFilter,
  clearFilterStays,
  setTourFilterActive,
  setStayFilterActive,
} = filterSlice.actions;

export default filterSlice.reducer;
