import React from "react";
import useOrders from "../../hooks/useOrders";
import { useSelector } from "react-redux";

function OrderSortHeader() {
  const { ticketStatusType, selectedTicketsSortHeader } = useSelector(
    (state) => state.order
  );
  const { getSortHeaderRow, selectSortHeader } = useOrders();
  
  console.log(getSortHeaderRow())
  return (
    <div className="w-full hidden md:flex flex-col pt-4">
      <div className="flex items-center gap-1 justify-between w-full ">
        {getSortHeaderRow().map(({ name, width, type }, index) => (
          <div
            key={index}
            onClick={() => selectSortHeader(index)}
            className={`h-12 ${width || "flex flex-1"} py-[1px] bg-[#F0F6FC] ${
              selectedTicketsSortHeader?.name === name &&
              "border border-[#B8D1FF]"
            }`}
          >
            <div
              className={`h-full w-full flex items-center justify-between px-4 text-xs cursor-pointer font-bold text-[#2E2E32] bg-[#B6CDF9]`}
            >
              <span></span>
              <span>{name}</span>
              <span>
                {selectedTicketsSortHeader?.name === name && (
                  <>
                    {type === "asc" ? (
                      <img src="/arrowDown.svg" alt="" />
                    ) : (
                      <img src="/arrowUp.svg" alt="" />
                    )}
                  </>
                )}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OrderSortHeader;
