import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  ticketType: "Flights",
  ticketFilterType: "All orders",
  ticketStatusType: "All",
  ticketSearchValue: "",
  ticketsPage: 1,
  ticketsPerPage: 10,
  ticketsSortHeader: null,
  selectedTicketsSortHeader: null,
  flightOrders: [],
  voidOrders: [],
  refundOrders: [],
  reissueOrders: [],
};

export const order = createSlice({
  name: "order",
  initialState,
  reducers: {
    setTicketType: (state, action) => {
      state.ticketType = action.payload;
    },
    setTicketFilterType: (state, action) => {
      state.ticketFilterType = action.payload;
    },
    setTicketStatusType: (state, action) => {
      state.ticketStatusType = action.payload;
    },
    setTicketSearchValue: (state, action) => {
      state.ticketSearchValue = action.payload;
    },
    setTicketsPage: (state, action) => {
      state.ticketsPage = action.payload;
    },
    setTicketsPerPage: (state, action) => {
      state.ticketsPerPage = action.payload;
    },
    setTicketsSortHeader: (state, action) => {
      state.ticketsSortHeader = action.payload;
    },
    setSelectedTicketsSortHeader: (state, action) => {
      state.selectedTicketsSortHeader = action.payload;
    },
    setFlightOrders: (state,action) => {
      state.flightOrders = action.payload;
    },
    setVoidOrders: (state,action) => {
      state.voidOrders = action.payload;
    },
    setRefundOrders: (state,action) => {
      state.refundOrders = action.payload;
    },
    setReissueOrders: (state,action) => {
      state.reissueOrders = action.payload;
    },
  },
});

export const {
  setTicketType,
  setTicketFilterType,
  setTicketSearchValue,
  setTicketStatusType,
  setTicketsPage,
  setTicketsPerPage,
  setTicketsSortHeader,
  setSelectedTicketsSortHeader,
  setFlightOrders,
  setVoidOrders,
  setRefundOrders,
  setReissueOrders,
} = order.actions;

export default order.reducer;
