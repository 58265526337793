import { MenuItem, Pagination } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTicketsPage, setTicketsPerPage } from "../../redux/reducers/orderSlice";
import useOrders from "../../hooks/useOrders";
import TextInput from "../form/TextInput";

function OrderPagination({callback,perPage=10}) {
  const { getOrders } = useOrders();
  const [ordersList,ogData] = getOrders() || [[],[]];

  console.log(ordersList)
  
  const count = Math.ceil(Math.max(ordersList?.length,perPage)/perPage)
  const {ticketsPage} = useSelector(state => state.order)
  // const [page, setPage] = useState(ticketsPage);
  const dispatch = useDispatch();

  const handlePageChange = (pg) => {
    // setPage(pg);
    dispatch(setTicketsPage(pg))
    callback && callback(pg)
  };

  const pages = [10,20,30,40];

  return (
    <div className="flex justify-end mt-10 gap-4">
      <Pagination
        count={count}
        onChange={(e, pg) => handlePageChange(pg)}
        page={ticketsPage||1}
        variant="outlined"
        shape="rounded"
      />
      <div className="inline-block">
        <TextInput select label='' className='!h-6 !text-xs'
          value={perPage}
          onChange={(ev) => {
            dispatch(setTicketsPerPage(ev.target.value || 10))
            dispatch(setTicketsPage(1))
          }}
          sx={{
            '& .MuiInputBase-root': {
              height: '32px', // Adjust the height as needed
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '4px', // Optional: Set border radius
            },
            '& .MuiInputLabel-root': {
              lineHeight: '32px', // Align label vertically
            },
          }}
        >
          {pages?.map((val,i) => (
            <MenuItem key={i} value={val}>{val} / page</MenuItem>
          ))}
        </TextInput>
      </div>
    </div>
  );
}

export default OrderPagination;
