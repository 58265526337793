import React, { useEffect, useState } from 'react'
import TextInput from '../../components/form/TextInput'
import { CircularProgress, MenuItem } from '@mui/material';
import createTask from '../../controllers/Tasks/createTask';
import Button1 from '../../components/form/Button1';
import updateTask from '../../controllers/Tasks/updateTask';
import getAdminTeamMembers from '../../controllers/settings/adminTeam/getTeamMembers';
import { enqueueSnackbar } from 'notistack';

export default function TaskForm({data: defData,callback}) {

    const [data,setData] = useState(defData || {
        "note": "",
        "taskType": "Ticketing", //Ticketing, Void, Refund, Support, Other
        "priority": "Medium", //Low, Medium, High, Critical
        "assignee": "",
        //optional depending on the task
        // "dueDate": "2024-10-28",
        // "ticketManagementId": "",
        // "flightBookingId": "",
        // "refundTicketManagementId": "",
        // "voidTicketManagementId": ""
    });
    const [staff,setStaff] = useState(data?.assignee);
    const [staffs,setStaffs] = useState([])
    
    const [loadings,setLoadings] = useState({submit:false});

    useEffect(() => {
      if(defData) {
        setData(defData)
        setStaff(defData?.assignee)
      }
      loadStaff()
    },[defData])
      
    async function loadStaff() {
      setLoadings({...(loadings||{}),fetchStaff: true})
      const res = await getAdminTeamMembers();
      setLoadings({...(loadings||{}),fetchStaff: false})
      if(res.return) {
        setStaffs(res?.data?.data||[])
      }
    }
  

    async function handleSubmit(ev) {
      ev?.preventDefault();

      const {note,taskType,priority} = data;
      const modData = {note,taskType,priority,assignee: staff};
      
      let res = {return: 0, msg: 'Something went wrong on our end! 0xCRTSK'}
      setLoadings({...loadings,submit: true});
      if(defData) res = await updateTask(defData?._id,modData)
      else res = await createTask(modData)
      setLoadings({...loadings,submit: false});

      if(res.return) {
          enqueueSnackbar(res.msg,{variant: 'success'})
          callback && callback();
      } else enqueueSnackbar(res.msg,{variant: 'error'})
    }

  return (
    <div>
      <form onSubmit={handleSubmit} className='flex flex-col gap-4 min-w-[400px] max-w-[90vw]'>
        <h5>{defData ? 'Update Task' : 'Create Task'}</h5>
        <div className='flex gap-4'>
          <TextInput select label='Task Type' 
              value={data?.taskType} 
              onChange={(ev) => setData({...data,taskType: ev.target.value})}
              >
              <MenuItem value='Ticketing'>Ticketing</MenuItem>
              <MenuItem value='Void'>Void</MenuItem>
              <MenuItem value='Refund'>Refund</MenuItem>
              <MenuItem value='Support'>Support</MenuItem>
          </TextInput>
          <TextInput select label='Priority' 
              value={data?.priority} 
              onChange={(ev) => setData({...data,priority: ev.target.value})}
              >
              <MenuItem value='Low'>Low</MenuItem>
              <MenuItem value='Medium'>Medium</MenuItem>
              <MenuItem value='High'>High</MenuItem>
              <MenuItem value='Critical'>Critical</MenuItem>
          </TextInput>
        </div>
        <TextInput label={'Note'} multiline rows={4}
          value={data?.note}
          onChange={(ev) => setData({...data,note: ev.target.value})} />

        <TextInput label='Select Staff' select
          value={staff}
          onChange={(ev) => setStaff(ev.target.value)}
          InputProps={{
            startAdornment: loadings?.fetchStaff ? <CircularProgress className='!w-4 !h-4' /> : null
          }}
        >
          {staffs?.map((obj,i) => (
            <MenuItem key={i} value={obj?._id || obj?.id}>{obj?.firstName} {obj?.lastName}</MenuItem>
          ))}
        </TextInput>

        <Button1 type='submit' loading={loadings?.submit}>{defData ? 'Update' : 'Submit'}</Button1>
      </form>
    </div>
  )
}
