import React, { useState } from "react";
import TicketManagementHeader from "../../../components/TicketManagement/TicketManagementHeader";
import { useDispatch } from "react-redux";
import TicketManagementLoadingModal from "../../../components/modal/TicketManagementLoadingModal";
import TicketManagementFlightAddBagStepsHeader from "../../../components/TicketManagement/TicketManagementFlightAddBagStepsHeader";
import { FormControl, MenuItem, Select } from "@mui/material";
import ConfirmFlightAddBagModal from "../../../components/modal/ConfirmFlightAddBagModal";
import { setConfirmFlightAddBagModal } from "../../../redux/reducers/modalSlice";

function TicketManagementFlightAddBag() {
  return (
    <div className="flex flex-1 flex-col w-full bg-[#F8F8F8] py-4">
      <ConfirmFlightAddBagModal />
      <TicketManagementLoadingModal />

      <TicketManagementHeader />
      <TicketManagementFlightAddBagStepsHeader step={1} />
      <div className="flex flex-col lg:flex-row gap-8 lg:gap-10 mt-6">
        <SelectBaggageAllowance />
        <PriceDetail />
      </div>
    </div>
  );
}

const people = [
  {
    firstName: "John",
    lastName: "Doe",
    totalPrice: "0 USD",
    flights: [
      { departure: "LOS", arrival: "KGL", options: ["1", "2"], selected: "" },
      { departure: "LOS", arrival: "KGL", options: ["1", "2"], selected: "" },
    ],
  },
  {
    firstName: "John",
    lastName: "Doe",
    totalPrice: "0 USD",
    flights: [
      { departure: "LOS", arrival: "KGL", options: ["1", "2"], selected: "" },
      { departure: "LOS", arrival: "KGL", options: ["1", "2"], selected: "" },
    ],
  },
];

const SelectBaggageAllowance = () => {
  const [passengers, setPassengers] = useState(people);
  console.log(passengers);
  return (
    <div className="flex flex-col flex-1 bg-white rounded-lg p-4">
      <span className="font-bold text-black mb-3">
        Select baggage allowance
      </span>
      {passengers?.map((passenger, index) => (
        <div
          className={`flex flex-col w-full gap-3 pb-3 mb-3 ${
            index !== passengers.length - 1 && "border-b"
          }`}
        >
          <div className="flex flex-col lg:flex-row gap-2 bg-[#F0F6FC] px-4 py-1 rounded-md justify-between text-sm">
            <span>
              Passenger {index + 1}: {passenger.firstName}/{passenger.lastName}
            </span>
            <span className="">
              Total Price:{" "}
              <span className="font-bold text-red-700">
                {passenger.totalPrice}
              </span>
            </span>
          </div>
          <div className="flex flex-col lg:flex-row flex-wrap gap-5">
            {passenger?.flights?.map((flight, flightIndex) => (
              <div
                key={flightIndex}
                className="flex flex-1 flex-col min-w-[30%]"
              >
                <div className="text-gray-500 font-bold text-sm flex items-center gap-1 mb-4">
                  <div className="px-3 py-1 rounded-full shadow-md bg-[#E7F6FF]">
                    <img
                      src="/IconPlane.svg"
                      alt=""
                      className="w-3 object-contain"
                    />
                  </div>
                  <span>
                    {flight.departure} - {flight.arrival}
                  </span>
                </div>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={flight.selected}
                    className="h-10"
                    onChange={(e) => {
                      setPassengers(
                        passengers.map((p, passIndex) => {
                          if (passIndex === index) {
                            p.flights[flightIndex].selected = e.target.value;
                          }
                          return p;
                        })
                      );
                    }}
                  >
                    {flight.options.map((option, flightOptionIndex) => (
                      <MenuItem value={option} key={flightOptionIndex}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const PriceDetail = () => {
  const dispatch = useDispatch();
  const [buttonActive, setButtonActive] = useState(false);

  const handlePay = () => {
    dispatch(setConfirmFlightAddBagModal(true));
  };

  return (
    <div className="flex flex-col border-2 w-full h-fit lg:w-96 bg-white rounded-lg p-4 gap-4">
      {people?.map((passenger, index) => (
        <div
          className="w-full flex items-center justify-between text-gray-400 text-sm"
          key={index}
        >
          <span>Passenger {index + 1}</span>
          <span className="text-black font-bold">{passenger.totalPrice}</span>
        </div>
      ))}
      <div className="w-full flex items-center justify-between text-gray-400 text-sm border-t py-4">
        <span>Total </span>
        <span className="font-bold text-red-700">0 USD</span>
      </div>
      <button
        className={`w-full h-12 rounded-md flex items-center justify-center font-bold text-sm ${
          buttonActive
            ? "bg-primary1 text-white"
            : " text-[#818083] bg-[#D9D9D9]"
        }`}
        onClick={handlePay}
      >
        Click to pay now
      </button>
    </div>
  );
};

export default TicketManagementFlightAddBag;
