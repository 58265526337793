import { useEffect, useState } from "react";
import TextInput from "./TextInput";

export default function NameInput({value: defValue,onChange,...props}) {
    const [value,setValue] = useState(defValue || '')
    const [err,setErr] = useState('')

    useEffect(() => {
      setValue(defValue || '');
    },[defValue])
  
    const handleData = (val) => {
      if(val?.includes(' '))
        return setErr('Space is not allowed!')
  
      setValue(val);
      onChange && onChange(val);
    }
    
    return (
      <TextInput {...props}
        inputProps={{
          pattern: "[a-zA-Z]+"
        }}
        helperText={err}
        value={value}
        onChange={(ev) => handleData(ev.target.value)}
      />
    )
  }