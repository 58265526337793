import { useEffect, useState } from "react";
import { formatMoney } from "../../../features/utils/formatMoney";
import Icon from "../../HOC/Icon";
import TextInput from "../../form/TextInput";

export const PriceBreakdown = ({data:defData,callback}) => {
    const [data,setData] = useState({
      penality: 0,
      ticketDifference: 0,
    })
    const [onEdit,setOnEdit] = useState(false);
    const [lock,setLock] = useState(true);

    useEffect(() => {
      if(lock)
        setLock(false)
      else
        callback && callback(data)
    },[data])
    
    useEffect(() => {
      setData({
        penality: defData?.fee||0,
        ticketDifference: defData?.ticketDifference||0
      })
    },[defData])
    
    return (
      <div className="w-full bg-[#F1F1F1] flex flex-col p-4 mt-4">
        <div className="flex justify-end text-primary1 text-end mb-2 text-sm lg:text-base">
          Price breakdown
        </div>
        <div>
            <div className="flex items-center gap-2 font-bold justify-between text-sm lg:text-base">
                <span className="">
                    Change Fee
                </span>
                <span className=" flex gap-2 items-center">
                {onEdit ? 
                  <div>
                    <TextInput type='number' size='small' label='Update fare difference' value={data?.ticketDifference}
                      onChange={(ev) => setData({...data,ticketDifference: ev.target.value})}
                    />
                  </div>
                :
                  formatMoney(data?.ticketDifference)
                }
                    <Icon icon={onEdit ? 'tabler:edit-off' : 'tabler:edit'} onClick={() => setOnEdit(!onEdit)} 
                      className='cursor-pointer text-primary/70 !w-5 !h-5' />
                </span>
            </div>
            <div className="flex items-center gap-2 justify-between !text-sm py-2">
                <p className="pl-4">
                    Service Charge
                </p>
                {onEdit ? 
                  <div>
                    <TextInput type='number' size='small' label='Update penality' value={data?.penality}
                      onChange={(ev) => setData({...data,penality: ev.target.value})}
                    />
                  </div>
                :
                  <p className="">{formatMoney(data?.penality)}</p>
                }
            </div>
        </div>
        {/* {breakdown.detail.map((item, index) => (
          <div key={index} className="flex flex-col mb-2">
            <div className="flex items-center gap-2 font-bold justify-between text-sm lg:text-base">
              <span className="">
                {item.quantity} x {item.feeName}
              </span>
              <span className="">{item.total}</span>
            </div>
            {item.items &&
              item.items?.map((subItem, subIndex) => (
                <div
                  key={subIndex}
                  className="flex items-center gap-2 justify-between text-gray-600 ml-2"
                >
                  <span className="">{subItem.name}</span>
                  <span className="">{subItem.price}</span>
                </div>
              ))}
          </div>
        ))} */}
        <div className="w-full border-b border-gray-300 mb-2"></div>
        <div className="flex items-center gap-2 font-bold justify-between text-sm lg:text-base">
            <span className="">Total</span>
            <div className="flex font-bold w-full justify-end text-end text-primary1 text-sm lg:text-base mt-2">
            -{formatMoney((Number(data?.penality) + Number(data?.ticketDifference)))}
            </div>
        </div>
        {/* <div className="flex font-bold w-full justify-end text-end text-[#6D7C94] text-sm lg:text-base mt-2">
          NOT including payment fee
        </div> */}
      </div>
    );
  };
  