import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setConfirmFlightAddBagModal,
  setConfirmTourBookingModal,
  setTicketManagementLoadingModal,
} from "../../redux/reducers/modalSlice";
import { useState } from "react";
import { Checkbox, Radio } from "@mui/material";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function ConfirmFlightAddBagModal() {
  const dispatch = useDispatch();
  const { confirmFlightAddBagModal } = useSelector((state) => state.modal);

  const handleOpen = () => dispatch(setConfirmTourBookingModal(true));
  const handleClose = () => dispatch(setConfirmFlightAddBagModal(false));

  return (
    <div>
      <Modal
        open={confirmFlightAddBagModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className={Classname.content}>
              <span className={Classname.modalTitle}>Confirm Add Bag</span>
              <Compare />
              <CTASection />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Compare = () => {
  const passengerItems = [
    {
      name: "2PC",
      weight: "23kg",
    },
    {
      name: "2PC",
      weight: "23kg",
    },
  ];
  return (
    <div className="flex flex-col justify-between lg:justify-start w-full mb-4 gap-3 border-b pb-4">
      <div className="flex flex-col lg:w-full gap-3 lg:flex-row lg:items-center justify-start">
        <div className="bg-[#DBDBDB] rounded-md w-full lg:w-40 h-9 flex items-center justify-center font-bold text-sm">
          Flight
        </div>
        <div className="flex flex-1 flex-wrap gap-3">
          <span className="font-bold text-sm lg:text-base">LOS-LHR</span>
          <span className="font-bold text-sm lg:text-base">
            Sat, 08 April 2024, <span className="bg-[#FDF3D7]">11:50pm</span>
          </span>
          <span className="font-bold text-sm lg:text-base">
            Cabin: <span className="text-gray-300">Economy</span>
          </span>
          <span className="font-bold text-sm lg:text-base">
            Bag: <span className="text-gray-300">2PC</span>
          </span>
        </div>
      </div>
      <div className="flex flex-col lg:w-full gap-3 lg:flex-row justify-start">
        <div className="bg-[#FDF3D7] rounded-md w-full lg:w-40 h-9 flex items-center justify-center font-bold text-sm">
          Add Bag
        </div>
        <div className="flex flex-col flex-1 mt-1">
          {passengerItems?.map((item, index) => (
            <div className="flex flex-1 flex-wrap gap-3" key={index}>
              <span className="font-bold text-sm lg:text-base">
                <span className="bg-[#FDF3D7]">{item?.name}</span>
              </span>
              <span className="font-bold text-sm lg:text-base">
                {item?.weight}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col lg:w-full gap-3 lg:flex-row items-center justify-start">
        <div className="bg-[#DBDBDB] rounded-md w-full lg:w-40 h-9 flex items-center justify-center font-bold text-sm">
          Fee
        </div>
        <span className="font-bold text-sm lg:text-base text-red-600">
          0 USD
        </span>
      </div>
    </div>
  );
};

const CTASection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [change, setChange] = useState(false);
  const [agree, setAgree] = useState(false);

  const handleGoBack = () => {
    dispatch(setConfirmFlightAddBagModal(false));
  };

  const handleConfirm = () => {
    dispatch(setConfirmFlightAddBagModal(false));
    dispatch(setTicketManagementLoadingModal(true));
    setTimeout(() => {
      dispatch(setTicketManagementLoadingModal(false));
      navigate("/order/flight/ticket/confirmation?type=addbag");
    }, 2000);
  };

  return (
    <div className="w-full flex flex-col gap-4 py-4 lg:py-10 ">
      <div className="w-full flex items-center gap-10">
        <button
          onClick={handleGoBack}
          className="px-3 lg:px-10 font-bold flex items-center justify-center h-12"
        >
          Go Back
        </button>
        <button
          onClick={handleConfirm}
          className="lg:px-10 font-bold flex-1 flex items-center justify-center h-12 bg-primary1 rounded-md text-white"
        >
          Confirm Change
        </button>
      </div>
      <div className="flex w-full items-center justify-center gap-2">
        <Checkbox
          checked={agree}
          onClick={() => setAgree(!agree)}
          size="small"
        />
        <span className="text-xs lg:text-sm">
          I agree to Intraverse{" "}
          <a href="" className="text-primary1">
            Service Agreement, Terms & Conditions and Privacy Policy
          </a>
        </span>
      </div>
    </div>
  );
};

const Classname = {
  container: "flex flex-col font-main",
  content: "flex flex-col items-center px-4",
  modalTitle:
    "text-xl lg:text-3xl font-bold mb-6 py-4 border-b w-full text-center",
  modalDescription: "text-gray-600 mb-6 text-center text-sm lg:text-base",
  modalTourName: "font-bold flex flex-col items-center text-center",
  bottomContent:
    "w-full flex items-center justify-center gap-4 py-4 lg:py-10 bg-gray-100 px-4",
  button: "h-16 rounded-md flex items-center justify-center w-60 font-bold",
  backButton: "text-black",
  confirmButton: "text-white bg-primary1",
  wait: "font-bold",
};
