import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userSlice";
import bookingDataReducer from "./reducers/flight/flightBookingSlice";
import filterSlice from "./reducers/tour/filterSlice";
import tabSlice from "./reducers/tour/tabSlice";
import tourDataSlice from "./reducers/tour/tourDataSlice";
import hotelDataSlice from "./reducers/tour/hotelDataSlice";
import dataSlice from "./reducers/tour/dataSlice";
import modalSlice from "./reducers/modalSlice";
import orderSlice from "./reducers/orderSlice";


export const store = configureStore({
  reducer: {
    flightBooking: bookingDataReducer,
    user: userReducer,
    tourFilter: filterSlice,
    tabSlice: tabSlice,
    tourData: tourDataSlice,
    hotelData: hotelDataSlice,
    data: dataSlice,
    modal: modalSlice,
    order: orderSlice,
  }
})