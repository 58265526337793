import { Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import uploadFile from "../../controllers/File/uploadFile";

const TicketManagementUploadDocument = ({uploader,callback}) => {
  const [documents, setDocuments] = useState([]);
  const [uploadStatus, setUploadStatus] = useState({0: 25});

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleUpload(files);
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    handleUpload(files);
  };

  const handleUpload = async (files) => {
      const allowedTypes = [
      "image/jpeg",
      "image/png",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/zip",
      "application/x-rar-compressed",
    ];

    const docs = Array.from(files).filter(
      (file) => allowedTypes.includes(file.type) && file.size <= 2 * 1024 * 1024 // size check for 2MB
    );

    const newDocuments = docs.map((file) => ({file,url: URL.createObjectURL(file)}));
    const fileData = [...documents,...newDocuments];
    setDocuments(fileData);
    const formData = new FormData();
    newDocuments?.map(obj => 
      formData?.append('files',obj?.file)
    )
    setUploadStatus({...(uploadStatus||{}),[fileData?.length-1]: 1})
    if(uploader) {
      const res = await uploadFile(formData,
        (progressEvent) => {
          // Calculate the percentage of the upload completed
          const total = progressEvent.total;
          const current = progressEvent.loaded;
          const percentage = Math.round((current * 100) / total);
      
          // Display the percentage
          // console.log(`Upload Progress: ${percentage}%`);
          setUploadStatus({...(uploadStatus||{}),[fileData?.length-1]: percentage})
        },
      );
      fileData[fileData?.length-1 || 0] = {...(fileData?.at(-1)||{}),uUrl: res?.data?.at(-1)}
    }
    // callback && callback(fileData)
    callback && callback(fileData?.map(obj => obj?.uUrl || obj))
    setDocuments(fileData);
    // console.log(res);
  };

  const handleClick = () => {
    document.getElementById("uploadInput").click();
  };

  function handleRemove(i) {
    const temp = documents?.filter((_,ind) => ind !== i);
    setDocuments(temp)
    callback && callback(temp?.map(obj => obj?.uUrl || obj))
  }

  console.log('up: ',uploadStatus)
  
  return (
    <div className={Classname.cardContainer}>
      <span className="font-bold text-black">Upload certification file</span>
      <div className={Classname.detailInfoContainer}>
        <img src="/IconInfo.svg" alt="" className="mb-2" />
        <span className="font-normal">
          Upload any document to validate the change request.
        </span>
      </div>
      <div className="flex flex-col p-4 shadow-md max-w-2xl pb-8">
        <span className="font-bold mb-2 text-black">File upload</span>
        <div
          className="flex flex-col font-normal justify-end items-center mt-6 mb-4 border-2 py-8 cursor-pointer"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={handleClick}
        >
          <input
            type="file"
            // accept=".jpeg, .jpg, .png, .pdf, .doc, .docx, .zip, .rar"
            onChange={handleFileUpload}
            className="hidden"
            id="uploadInput"
            multiple
          />
          <img
            src="/IconUpload.svg"
            alt=""
            className="w-20 object-contain mb-2"
          />
          <div className="text-center text-sm text-gray-400">
            Drop your files here or click to browse
          </div>
          <div className="text-center text-sm text-black">
            Jpeg, png, doc, pdf, zip, rar, Size not more than 2M
          </div>
        </div>

        {documents.map((doc, index) => (
          <DocDisplay key={index} data={doc} remove={() => handleRemove(index)} uploadPercent={uploadStatus?.[index]} />
        ))}
      </div>
      {/* <div className="flex flex-wrap gap-2">
        {documents.map((doc, index) => (
          <div key={index} className="p-2 border rounded">
            <a href={doc?.url} target="_blank" rel="noopener noreferrer">
              {doc?.file?.name}
            </a>
          </div>
        ))}
      </div> */}
    </div>
  );
};

const DocDisplay = ({data,remove,uploadPercent}) => {
  const [uploadStatus,setUploadStatus] = useState(0);
  
  useEffect(() => {
    if(uploadStatus<100)
      setUploadStatus((uploadStatus||0)+1)

    //eslint-disable-next-line
  },[])

  return (
    <div className="w-full justify-between gap-2 flex items-center">
      <div className="flex flex-1 flex-col ">
        <div className="w-full flex items-center justify-center gap-4">
          <span className="flex flex-1 text-start text-sm text-black">
            {data?.file?.name}
          </span>
          <span className="flex text-black text-sm">{(Number(data?.file?.size) / (1024 * 1024)).toFixed(2)} MB</span>
        </div>
        <div className="w-full h-3 rounded-full bg-primary1/30">
          <div
            className={` h-full rounded-full bg-primary1`} style={{width: (uploadPercent||0)+'%'}}
          ></div>
        </div>
      </div>
      <div className="p-4">
        <Close onClick={() => remove && remove()} />
      </div>
    </div>

  )
}

const Classname = {
  detailInfoContainer:
    "w-full bg-blue-500/10 flex items-start lg:items-center gap-2 mb-4 px-2 lg:px-6 py-2 rounded-md text-sm mt-6",
  detailInfoIcon: "",
  cardContainer:
    "flex flex-1 flex-col w-full p-2 lg:p-4 bg-white mt-6 rounded-md",
};

export default TicketManagementUploadDocument;
