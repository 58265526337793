import React from "react";
import { URLdecode } from "../../../utils/url";
import TicketManagementHeader from "../../../components/TicketManagement/TicketManagementHeader";
import { useNavigate } from "react-router-dom";

function TicketManagementFlightConfirmation() {
  const params = URLdecode();
  const navigate = useNavigate();
  const handleContinue = () => {
    navigate("/order/flight/ticket/detail");
  };

  const handleTitle = () => {
    switch (params.type) {
      case "dateChange":
        return "Date change has been successfully submitted.";
      case "nameChange":
        return "Name change has been successfully submitted.";
      case "refund":
        return "Refund request has been successfully submitted.";
      case "void":
        return "Void request has been successfully submitted.";
      case "addbag":
        return "Add bag request has been successfully submitted.";
      default:
        return "Ticket Confirmation";
    }
  };

  return (
    <div className="flex flex-1 flex-col items-center w-full bg-[#F8F8F8] py-4">
      <TicketManagementHeader />
      <div className="bg-white px-4 lg:px-20 w-full max-w-5xl flex items-center flex-col mt-10 py-6">
        <div className="flex flex-col-reverse lg:flex-row items-center gap-3 lg:gap-10">
          <div className="flex flex-1 flex-col items-center text-center font-semibold">
            <span className="text-sm lg:text-base text-center">
              {handleTitle()}
            </span>
            <span className="text-sm lg:text-base text-center">
              We will notify once completed.
            </span>
          </div>
          <div className="bg-[#0B662C]/10 text-[#0B662C] text-sm py-2 px-4 h-fit w-fit">
            Completed
          </div>
        </div>
        <button
          className="py-2 flex flex-1 w-full mt-3 max-w-lg rounded-md text-sm font-bold bg-primary1 text-white items-center justify-center"
          onClick={handleContinue}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default TicketManagementFlightConfirmation;

const Classname = {
  detailInfoContainer:
    "w-full bg-blue-500/10 flex items-start lg:items-center gap-2 mb-4 px-2 lg:px-6 py-2 rounded-md text-sm mt-6",
  detailInfoIcon: "",
  cardContainer:
    "flex flex-1 flex-col w-full p-2 lg:p-4 bg-white mt-6 rounded-md",
  input: "flex flex-1 py-3 rounded-md px-4 border-2 border-gray-200",
};
