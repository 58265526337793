import { useState } from "react";
import { Modal3 } from "../../../../components/DIsplay/Modal/Modal3";
import ServiceStandard from "../../../../components/flight/Price/ServiceStandard";
import FareDisplay from "../FareDisplay.jsx";

export const FareRules = ({order}) => {
    const [openFareRule,setOpenFareRule] = useState(false);
    const [openOriginalFareDetail,setOpenOriginalFareDetail] = useState(false);
    const [openServiceCharge,setOpenServiceCharge] = useState(false);
    const handleViewFareRules = () => {
      setOpenFareRule(true)
    };
  
    const orderData = order?.booking?.flightBooking?.at(0);
  
    const refundable = orderData?.isRefundable;
    const changable = orderData?.isChangeable;
  
    const Card = ({title,description,status}) => {
      return (
        <div className="flex flex-col flex-1 rounded-xl p-4 bg-[#F0F6FC]">
          <span className="text-[#032D5F] font-bold text-lg">
            {title}
          </span>
          <div className="items-center gap-2 mt-2 flex">
            {status ? 
              <img
                src="/IconCheckmark1.svg"
                alt=""
                className="w-6 h-6 object-contain"
              />        
            :
              <img
                src="/IconClose5.svg"
                alt=""
                className="w-6 h-6 object-contain"
                />
            }
            <span className="text-sm">{!status ? description?.at(0) : description?.at(1)}</span>
          </div>
        </div>
      )
    }
  
    const miniRules = order?.fareRules
  
    return (
      <div className="w-full flex flex-col mt-6">
        <span className="font-bold text-xl mb-3">FareRules</span>
        <div className="flex flex-col justify-between p-4 bg-white shadow-md rounded-lg border">
          <div className="flex flex-col lg:flex-row gap-4 w-full justify-between">
            <Card title='Order change policy' 
              description={['This order is not changeable','This order is changeable']} 
              status={changable}
            />
            <Card title='Order refund policy' 
              description={['This order is not refundable','This order is refundable up until the initial departure date']} 
              status={refundable}
            />
          </div>
          <div className="flex gap-x-6 flex-wrap items-center">
            <span
              onClick={setOpenServiceCharge}
              className="font-bold text-primary1 cursor-pointer mt-4"
            >
              Service Charge
            </span>
            <span
              onClick={handleViewFareRules}
              className="font-bold text-primary1 cursor-pointer mt-4"
            >
              View Fare Rules
            </span>
          </div>
          <Modal3 open={openServiceCharge} setOpen={setOpenServiceCharge}
            title='Service Standard'>
              <div className="p-10">
                <ServiceStandard />
              </div>
          </Modal3>
  
          <Modal3 open={openFareRule} setOpen={setOpenFareRule} title={'Fare rules'}>
            <div className="p-10 flex">
              <FareDisplay data={orderData} openOg={() => setOpenOriginalFareDetail(true)} />
            </div>
          </Modal3>
          <Modal3 open={openOriginalFareDetail} setOpen={setOpenOriginalFareDetail} title='Fare rule'>
            <div className="card p-5 flex flex-col gap-4 border-primary relative w-[800px] max-w-full ">
                {/* <Icon icon='carbon:close-filled' className="btn_close self-end sticky top-2" onClick={() => setOpenFareRule(false)} /> */}
                {/* {loading ? <div className="load self-center"></div> : null} */}
                {
                  (!miniRules || !miniRules?.length) ? 
                    <div className="flex justify-center ">No Fare Applicable</div>
                  :null
                }
                <DisplayFareRules data={miniRules} />
                {/* {
                  miniRules?.map((obj,h) => (
                    <div key={h}>
                      {
                        Object.entries(obj)?.map(([key,val],i) => (
                          <div className="flex gap-3 items-center justify-between" key={i}>
                            {key === 'category' ? <b>{val}</b> 
                            : 
                            <div>
                              {key}: {(val)?.toString()}
                            </div>
                            }
                          </div>
                        ))
                      }
                    </div>
                  ))
                } */}
              </div>
          </Modal3>
        </div>
      </div>
    );
  };
  
  const DisplayFareRules = ({ data }) => {
    const renderRules = (rules) => {
      return rules.map((rule, index) => (
        <div key={index} style={{ margin: '10px 0' }}>
          <b>{rule.type}</b>
          <p>{rule.description}</p>
        </div>
      ));
    };
  
    const renderFares = (fares) => {
      return fares?.map((fareItem, index) => (
        <div key={index} style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0' }}>
          <h5>{fareItem.fare}</h5>
          {renderRules(fareItem.rule)}
        </div>
      ));
    };
  
    return (
      <div>
        {renderFares(data)}
      </div>
    );
  };