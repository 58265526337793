import Icon from "../../HOC/Icon";
import Modal1 from "./Modal1";

export function Modal3({children,title,...props}) {
  return (
    <Modal1 {...props}>
      <div className="min-w-[300px]">
        <div className="bg-blue-500 text-white p-4 flex gap-4 justify-between">
          {title}
          <Icon icon='mdi:close' className='cursor-pointer' onClick={() => props?.setOpen && props?.setOpen(false)} />
        </div>
        <div className="">
          {children||''}
        </div>
      </div>
    </Modal1>
  )
}