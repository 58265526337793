import fetchServer from "../../fetchServer";

export default async function getFees(){

    let result = {return: 0, msg: "Something went wrong fetching fees"}

    await fetchServer({method: "GET", url: "/payment/v1/fee/get"})
    .then((res) => {
        console.log('thisres', res)
        if (res?.data && !res?.data?.error){
            result = {return: 1, msg: "Successful", data: res?.data?.data};
            console.log('result', result)
        }
        else if (res?.data?.error) result["msg"] = res.data.error
    })
    .catch((err) => {
        console.log("Network Error!")
    })

    return result;
}