import React from 'react'
import Collapsible from '../../../components/DIsplay/Collapsible'
import { formatMoney } from '../../../features/utils/formatMoney'
import Icon from '../../../components/HOC/Icon'

export default function FareDisplay({data,openOg}) {
    const CMenu = ({children}) => (
        <div className='bg-theme1/10 p-4'>
            {children}
        </div>
    )
  return (
    <div className='flex'>
        <div className='border-r'>
            {data?.flights?.map((obj,i) => (
                <label className='block bg-primary/5 text-sm'>
                    <Collapsible header={
                        <div className='flex items-center gap-6 p-4 pr-0'>
                            <span>{obj?.departureLocation} - {obj?.arrivalLocation}</span>
                            <p>
                                Penalities
                            </p>
                        </div>
                    }
                     DropDown={
                        <div className=''>
                            <Icon icon='nrk:arrow-dropdown' />
                        </div>
                    }
                    >
                        <CMenu>Penalities</CMenu>
                    </Collapsible>
                </label>
            ))}
        </div>
        <div className="flex-1">
            <div className='border-b p-4 text-theme1'>For Adult</div>
            <div className='p-4'>
                <h5>Penalities</h5>
                <p className='!text-red-500'>
                    The following content is for reference only, click to view <button className='text-theme1' onClick={() => openOg && openOg()}>original</button>.
                </p>
                <div className='flex flex-col gap-4 py-4'>
                    <table>
                        <tr className='!bg-theme1/5'>
                            <td className='!p-4'>Before departure</td>
                            <td className='!p-4'>Charge</td>
                        </tr>
                        <tr>
                            <td>Refund</td>
                            <td>{formatMoney(10000)}</td>
                        </tr>
                        <tr>
                            <td>No Show Refund</td>
                            <td>{formatMoney(10000)}</td>
                        </tr>
                        <tr>
                            <td>Reissue</td>
                            <td>{formatMoney(10000)}</td>
                        </tr>
                        <tr>
                            <td>No Show Reissue</td>
                            <td>{formatMoney(10000)}</td>
                        </tr>
                    </table>
                    <table>
                        <tr className='!bg-theme1/5'>
                            <td className='!p-4'>After departure</td>
                            <td className='!p-4'>Charge</td>
                        </tr>
                        <tr>
                            <td>Refund</td>
                            <td>{formatMoney(10000)}</td>
                        </tr>
                        <tr>
                            <td>No Show Refund</td>
                            <td>{formatMoney(10000)}</td>
                        </tr>
                        <tr>
                            <td>Reissue</td>
                            <td>{formatMoney(10000)}</td>
                        </tr>
                        <tr>
                            <td>No Show Reissue</td>
                            <td>{formatMoney(10000)}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>      
    </div>
  )
}
