export function getStatusName(val) {
    switch(val) {
        case 'TicketCancelationRequested':
            return 'Awaiting Cancellation';
        case 'PendingVoid':
            return 'Awaiting Void'
        default: return val;
    }
}

export function getGeneralStatus(status) {
    switch(status) {
        case "Approved" :
        case "PendingVoid" :
        case "Pending" :
            return "processing"
        case "PendingPayment" :
            return "Awaiting Payment"
        case "Paid" :
            return "in process";
        case "Success" :
            return "Success"
        case "Rejected" :
        case "Denied" :
            return "Rejected"
        case "Failed" :
            return "Failed"
        case "Closed" :
            return "Closed"
        default: return status
    }
}