import React from 'react'
import ToolTip from '../../DIsplay/ToolTip'
import { Link } from 'react-router-dom'
import { formatMoney } from '../../../features/utils/formatMoney'

export default function ServiceStandard() {
    const data = [
        {
          type: "Issue",
          time: "MON - SUN 08:00 - 07:59 +1",
          fee: 0
        },
        {
          type: "Change",
          time: "MON - SUN 08:00 - 07:59 +1",
          fee: 10000
        },
        {
          type: "Refund",
          time: "MON - SUN 08:00 - 07:59 +1",
          fee: 10000
        },
        {
          type: "Void",
          time: "MON - SUN 08:00 - 07:59 +1",
          fee: 10000
        }
    ]
  return (
    <div className='flex flex-col gap-4 max-w-[800px]'>
      <table>
        <thead className='bg-theme1/10'>
            <tr>
                <td className='p-5'>SERVICE TYPE</td>
                <td className='p-5'>SERVICE TIME (GMT+01:00)</td>
                <td className='p-5'>SERVICE FEE</td>
            </tr>
        </thead>
        <tbody>
            {data?.map((obj,i) => (
                <tr key={i}>
                    <td className='p-5'>{obj?.type}</td>
                    <td className='p-5'>{obj?.time}</td>
                    <td className='p-5'>{obj?.fee ? formatMoney(obj?.fee) : '---'}</td>
                </tr>
            ))}
        </tbody>
      </table>
      <div className='flex gap-4 items-center'>
        <ToolTip>
            The above service hours may be subjected to change due to local holidays at the ticketing location. Please verify the specific hours when processing your request.
        </ToolTip>
        {/* <Link to='#' className='text-blue-500 whitespace-nowrap'>Serivce details</Link> */}
      </div>
    </div>
  )
}
