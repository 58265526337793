export default function segmentFlights(data) {
    const segmentedData = [];
  
    data?.forEach((item) => {
      const segmentIndex = parseInt(item.segment||1, 10) - 1;
      if (!segmentedData[segmentIndex]) {
        segmentedData[segmentIndex] = [];
      }
      segmentedData[segmentIndex].push(item);
    });
  
    return segmentedData;
}