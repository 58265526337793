import React, { useEffect, useState } from "react";
import TicketManagementFlightChangeStepsHeader from "../../../components/TicketManagement/TicketManagementFlightChangeStepsHeader";
import { CircularProgress, FormControl, MenuItem, Select } from "@mui/material";
import ConfirmFlightDateChangeModal from "../../../components/modal/ConfirmFlightDateChangeModal";
import { useDispatch } from "react-redux";
import { setConfirmFlightDateChangeModal } from "../../../redux/reducers/modalSlice";
import TicketManagementLoadingModal from "../../../components/modal/TicketManagementLoadingModal";
import TicketManagementHeader from "../../../components/TicketManagement/TicketManagementHeader";
import { useLocation, useParams } from "react-router-dom";
import { decrypt } from "../../../features/utils/crypto";
import requestFlightChange from "../../../controllers/booking/postTicketing/change/requestFlightChange";
import Paginate from "../../../components/DIsplay/Paginate";
import getBooking from "../../../controllers/booking/getBooking";

function TicketManagementFlightSearchFlight() {
  const {id} = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const q = searchParams.get('q')
  const qObj = JSON.parse(q ? decrypt(q) : '{}')
  const [data,setData] = useState([])
  const [oldOrder,setOldOrder] = useState();

  const [loading,setLoading] = useState({load: true})
  
  console.log(qObj)

  useEffect(() => {
    fetch();
    //eslint-disable-next-line
  }, [id]);

  const fetch = async () => {
    if(!id) return setLoading({...loading,fetching: false});

    setLoading({...loading,fetching: true});
    const res = await getBooking(id);
    setLoading({...loading,fetching: false});
    if (res.return) {
      setOldOrder(res.data);
    }
  };


  useEffect(() => {
    load();
    //eslint-disable-next-line
  },[q])

  async function load() {
    setLoading({...loading,load: true})
    const res = await requestFlightChange(qObj)
    setLoading({...loading,load: false})
    if(res.return) {
      console.log(res?.data)
      setData(res?.data)
      // callback && callback()
    }
  }
  
  return (
    <div className="flex flex-1 flex-col w-full bg-[#F8F8F8] py-4">
      <TicketManagementHeader />
      <TicketManagementFlightChangeStepsHeader step={2} />
      <TicketManagementLoadingModal />
      <div className={Classname.cardContainer}>
        <ContentHeader />
        {loading?.load ? 
          <div className="flex justify-center py-4">
            <CircularProgress />
          </div>
        :!data?.length ? 
          <div className="flex justify-center py-4">
            No Flights Returned
          </div>
        :null}
        <SearchResult data={data} query={qObj} oldOrder={oldOrder} />
      </div>
    </div>
  );
}

const ContentHeader = () => {
  const handleEdit = () => {};

  return (
    <div className="flex flex-col w-full py-2">
      <span className="font-bold text-xl mb-4">Showing search result for</span>
      <div className="flex flex-wrap flex-col lg:flex-row gap-3">
        <span className="h-12 flex items-center px-6 font-bold rounded-md bg-gray-100 text-gray-600">
          LOS - LON
        </span>
        <span className="h-12 flex items-center px-6 font-bold rounded-md bg-gray-100 text-gray-600">
          Economy
        </span>
        <span className="h-12 flex items-center px-6 font-bold rounded-md bg-gray-100 text-gray-600">
          Wed, 26th Oct
        </span>
        <button
          className="h-12 flex items-center justify-center px-6 font-bold rounded-md bg-primary1 text-white "
          onClick={handleEdit}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

function templateData(obj) {
  return {
    og: obj,
    id: obj?.segmentRef,
    airline: {
      name: obj?.airline?.image?.description,
      image: obj?.airline?.image?.url,
    },
    departure: {
      name: obj?.departure?.airport,
      code: obj?.departure?.location,
      time: obj?.departure?.time,
    },
    destination: {
      name: obj?.arrival?.airport,
      code: obj?.arrival?.location,
      time: obj?.arrival?.time,
    },
    classes: ["J", "K"],
    selectedClass: "",
    time: obj?.duration,
    stopovers: obj?.numberOfStops,
  
  }
}
const SearchResult = ({data,oldOrder,query}) => {
  return (
    <div className="w-full flex flex-col gap-4 mt-6">
      <ConfirmFlightDateChangeModal />
      <Paginate data={data} limit={10} className="flex flex-col gap-4 pt-4"
        render={(obj,index) => 
          <Flight key={index} flight={templateData(obj)} {...{query,oldOrder}} />
        }
      />
    </div>
  );
};

const Flight = ({ flight: selectedFlight, oldOrder, query }) => {
  const dispatch = useDispatch();
  const [flight, setFlight] = useState({});

  useEffect(() => {
    setFlight(selectedFlight)
  },[selectedFlight])
  const handleSelect = () => {
    dispatch(setConfirmFlightDateChangeModal({flight,oldOrder,query}));
  };
  return (
    <div className="px-2 lg:px-6 py-4 rounded-md border border-gray-300 flex flex-col lg:flex-row items-center justify-between">
      <div className="lg:px-6 flex flex-row lg:flex-col items-center gap-2 lg:border-r border-b lg:border-b-0 pb-2 lg:pb-0 lg:justify-center lg:h-36 w-full lg:w-fit">
        <img
          src={flight?.airline?.image}
          alt=""
          className="w-6 lg:w-24 lg:h-24 object-contain"
        />
        <span className="font-bold flex flex-1 text-sm lg:text-base">
          {flight?.airline?.name}
        </span>
      </div>
      <div className="flex flex-1 flex-col lg:flex-row items-center lg:px-6 gap-6">
        <div className="flex flex-1 flex-col-reverse lg:flex-col">
          <div className="flex flex-1 items-end justify-between mt-2 lg:mt-0">
            <div className="flex flex-col gap-1">
              <span className="text-sm">{flight?.departure?.name}</span>
            </div>
            <div className="flex flex-col gap-1 items-end">
              <span className="text-sm text-end">
                {flight?.destination?.name}
              </span>
            </div>
          </div>
          <div className="flex flex-1 items-center lg:items-end justify-between mt-2 lg:mt-0">
            <div className="flex flex-col gap-1">
              <span className="font-bold">{flight?.departure?.code}</span>
              <span className="font-bold">{flight?.departure?.time}</span>
            </div>
            <div className="flex flex-col items-center">
              <div className="flex gap-1 items-center">
                <img
                  src="/IconClock1.svg"
                  alt=""
                  className="w-3 object-contain"
                />
                <span className="text-xs">{flight?.time}</span>
              </div>
              <div className="flex gap-1 items-center">
                <img
                  src="/IconPlane.svg"
                  alt=""
                  className="w-3 object-contain"
                />
                <span className="text-xs text-primary1">
                  {flight?.stopovers} stopovers
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-1 items-end">
              <span className="font-bold">{flight?.destination?.code}</span>
              <span className="font-bold">{flight?.destination?.time}</span>
            </div>
          </div>
        </div>
        {/* <div className="w-full lg:w-48 mb-3 lg:mb-0 flex items-center">
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={flight?.selectedClass}
              placeholder="Select class"
              className="h-10"
              onChange={(e) => {
                setFlight({ ...flight, selectedClass: e.target.value });
              }}
            >
              {flight?.classes?.map((cls, idx) => (
                <MenuItem value={cls} key={idx}>
                  {cls}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div> */}
      </div>
      <div className="h-full lg:px-6 flex items-center justify-center lg:border-l lg:h-36 w-full lg:w-fit">
        <button
          className="font-bold w-full lg:w-36 h-12 rounded-md border font items-center justify-center "
          onClick={handleSelect}
        >
          Select
        </button>
      </div>
    </div>
  );
};

export default TicketManagementFlightSearchFlight;

const Classname = {
  detailInfoContainer:
    "w-full bg-blue-500/10 flex items-start lg:items-center gap-2 mb-4 px-2 lg:px-6 py-2 rounded-md text-sm mt-6",
  detailInfoIcon: "",
  cardContainer:
    "flex flex-1 flex-col w-full p-2 lg:p-4 bg-white mt-6 rounded-md",
  input: "flex flex-1 py-3 rounded-md px-4 border-2 border-gray-200",
};
