/**@type {string[]} */
export const RolesData = [
  "ALL",
  "AMADEUS_SEARCH",
  "AMADEUS_BOOK",
  "AMADEUS_TICKET",
  "AMADEUS_CANCEL",
  "AMADEUS_IMPORT",
  "AMADEUS_REFUND",
  "AMADEUS_REISSUE",
  "AMADUES_HOTEL_SEARCH",
  "AMADUES_HOTEL_BOOK",
  "AMADUES_HOTEL_CANCEL",
  "HOTELBEDS_SEARCH",
  "HOTELBEDS_BOOK",
  "HOTELBEDS_CANCEL",
  "TOURPRO_SEARCH",
  "TOURPRO_BOOK",
  "TOURPRO_CANCEL",
]

export const RolesObj = {
  "ALL":"ALL",
  "AMADEUS_SEARCH":"AMADEUS_SEARCH",
  "AMADEUS_BOOK":"AMADEUS_BOOK",
  "AMADEUS_TICKET":"AMADEUS_TICKET",
  "AMADEUS_CANCEL":"AMADEUS_CANCEL",
  "AMADEUS_IMPORT":"AMADEUS_IMPORT",
  "AMADEUS_REFUND":"AMADEUS_REFUND",
  "AMADEUS_REISSUE":"AMADEUS_REISSUE",
  "AMADUES_HOTEL_SEARCH":"AMADUES_HOTEL_SEARCH",
  "AMADUES_HOTEL_BOOK":"AMADUES_HOTEL_BOOK",
  "AMADUES_HOTEL_CANCEL":"AMADUES_HOTEL_CANCEL",
  "HOTELBEDS_SEARCH":"HOTELBEDS_SEARCH",
  "HOTELBEDS_BOOK":"HOTELBEDS_BOOK",
  "HOTELBEDS_CANCEL":"HOTELBEDS_CANCEL",
  "TOURPRO_SEARCH":"TOURPRO_SEARCH",
  "TOURPRO_BOOK":"TOURPRO_BOOK",
  "TOURPRO_CANCEL":"TOURPRO_CANCEL",
}