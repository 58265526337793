import { useState } from "react";
import Icon from "../../../../components/HOC/Icon";
import { Modal3 } from "../../../../components/DIsplay/Modal/Modal3";

export const Baggage = ({order}) => {
    const orderData = order?.booking?.flightBooking?.at(0)
    const [openDetail,setOpenDetail] = useState(false);
    
    return (
      <div className="w-full flex flex-col mt-6">
        <span className="font-bold text-xl mb-3">Baggage</span>
        {orderData?.travelers?.filter(obj => obj.travelerType === 'ADT')?.map((obj,i) => (
          <div key={i} className="flex flex-col justify-between p-4 bg-white shadow-md rounded-lg border">
            <span className="flex gap-4 justify-between">
              Passenger {i+1}
              <button onClick={() => setOpenDetail(obj)} className="gap-2 text-sm flex-1 flex justify-end items-center text-primary/60">
                Details
                <Icon icon='cuida:open-in-new-tab-outline' className='!w-4 !h-4 !text-theme1' />
              </button>
            </span>
            <span>{orderData?.flights?.at(0)?.baggageAllowance ? '2PC' : '1PC'}</span>
          </div>
        ))}
        <Modal3 open={openDetail} setOpen={setOpenDetail} title='Free Baggage'>
          <div className="flex flex-col gap-4 p-6 max-w-[700px]">
          <table>
            <tr>
              <th>BEFORE DEPARTURE</th>
              <th>PASSENGER</th>
              <th>BAGGAGE ALLOWANCE</th>
              <th>CARRY ON ALLOWANCE</th>
            </tr>
            {orderData?.flights?.map((obj,i) => (
              <tbody key={i}>
                <tr>
                  <td rowSpan={3}>{obj?.departureLocation} - {obj?.arrivalLocation}</td>
                  <td>Adult</td>
                  <td>{obj?.baggageAllowance}</td>
                  <td>{(obj?.chargeableCheckedBags || [])?.join(', ') || '---'}</td>
                </tr>
                <tr>
                  <td>Child</td>
                  <td>Be subject to airline rules</td>
                  <td>Be subject to airline rules</td>
                </tr>
                <tr>
                  <td>Infant</td>
                  <td>Be subject to airline rules</td>
                  <td>Be subject to airline rules</td>
                </tr>
              </tbody>
            ))}
            </table>
            <div className='tooltip'>
              The penalties and baggage allowance may vary considerably in different segment. Please check all corresponding penalties and baggage accordingly. If you have any questions, please contact our 24/7 online Customer Service.
            </div>
          </div>
        </Modal3>
      </div>
    );
  };
  