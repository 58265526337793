import { Check } from "@mui/icons-material";
import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CalendarInput1 from "../../../components/form/CalendarInput1";
import TicketManagementFlightChangeStepsHeader from "../../../components/TicketManagement/TicketManagementFlightChangeStepsHeader";
import TicketManagementHeader from "../../../components/TicketManagement/TicketManagementHeader";
import TicketManagementPassengerSelection from "../../../components/TicketManagement/TicketManagementPassengerSelection";
import TicketManagementUploadDocument from "../../../components/TicketManagement/TicketManagementUploadDocument";
import TicketManagementContactDetails from "../../../components/TicketManagement/TicketManagementContactDetails";
import TicketManagementAddRemark from "../../../components/TicketManagement/TicketManagementAddRemark";
import { useNavigate, useParams } from "react-router-dom";
import getBooking from "../../../controllers/booking/getBooking";
import { SelectReason } from "./SelectReason";
import { useSelector } from "react-redux";
import ChangeFlight from "./ChangeFlight";
import moment from "moment";
import requestFlightChange from "../../../controllers/booking/postTicketing/change/requestFlightChange";
import { encrypt } from "../../../features/utils/crypto";

function TicketManagementFlightChangeDate() {
  const {id} = useParams()
  const [order, setOrder] = useState();
  // const [passengers,setPassengers] = useState([]);
  const [reason,setReason] = useState('');
  const [remark,setRemark] = useState('');
  const [documents,setDocuments] = useState([]);
  const [flights,setFlights] = useState();
  const [step,setStep] = useState(1)

  const {user} = useSelector(state => state.user.userData);
  const userName = (user?.firstName||'') + ' ' + (user?.middleName||'') + ' ' + (user?.lastName||'');

  const navigate = useNavigate();

  const [contact,setContact] = useState({
    name: userName || '',
    email: user?.email || '',
    phone: user?.phone || '',    
  })


  const [loading,setLoading] = useState({submit: false, fetching: true, confirmFee: false});

  useEffect(() => {
    fetch();
    //eslint-disable-next-line
  }, [id]);

  const fetch = async () => {
    if(!id) return setLoading({...loading,fetching: false});

    setLoading({...loading,fetching: true});
    const res = await getBooking(id);
    setLoading({...loading,fetching: false});
    if (res.return) {
      setOrder(res.data);
    }
  };
  const bookingData = order?.booking?.flightBooking?.at(0);

  async function handleSearch() {
    const payload = {
      "adult": bookingData?.adult?.passengerCount || 0,
      "child": bookingData?.child?.passengerCount || 0,
      "infant": bookingData?.infant?.passengerCount || 0,
      "flightBookingId": bookingData?._id,
      "changeRequest": "Flight", //Flight , PassengerInfo
      ...flights,
      "remark": remark,
      "reason": reason,
      "directChange": false,
      "contactDetails": contact,
      relatedDocs: documents,
    }

    // return console.log(payload)
    navigate(`/order/flight/ticket/searchflight/${id}?q=${encrypt(JSON.stringify(payload))}`)
  }
  return (
    <div className="flex flex-1 flex-col w-full bg-[#F8F8F8] py-4">
      {/* <div className="w-full lg:text-lg font-bold text-gray-400">
        {`Orders > FEW34X >`} <span className="text-black">Change Date</span>
      </div> */}
      <TicketManagementHeader />
      <TicketManagementFlightChangeStepsHeader step={1}/>
      {/* <TicketManagementPassengerSelection order={order} callback={(passengers) => setPassengers(passengers)} /> */}
      <SelectReason callback={setReason}/>
      <TicketManagementUploadDocument uploader callback={(obj) => setDocuments(obj?.map(oj => oj?.file || oj))} />
      <TicketManagementAddRemark callback={setRemark} />
      <TicketManagementContactDetails order={order} data={contact} callback={(obj) => setContact({...contact,...obj})} />
      <SelectJourney order={order} callback={(obj) => setFlights(obj)} />
      <TicketActionButtons callback={() => handleSearch()} />
    </div>
  );
}

// const journeys = [
//   {
//     selected: true,
//     name: "LOS - ADD",
//     date: "",
//     cabin: "",
//     cabinOptions: ["J", "K"],
//   },
// ];

const SelectJourney = ({order,callback}) => {
  const bookingData = order?.booking?.flightBooking?.at(0);
  const [segments, setSegments] = useState([]);
  const calendarRef = useRef([...Array(segments.length)]);
  const [lock,setLock] = useState(true);
  
  useEffect(() => {
    if(!lock)
      callback({
        "directionsToChange": segments?.filter(obj => obj?.selected)?.map(obj => obj?.segmentRef),
        "newDirections": segments?.filter(obj => obj?.selected)?.map(({from,to,date,cabin,...obj}) => (
            {
                from, to,
                "departure": {
                    "date": moment(date)?.format('YYYY-MM-DD')
                },
                "journeyType": from === segments?.at(0)?.to ? 'Inbound' : 'Outbound', //Inbound, Outbound
                cabinClass: cabin || undefined,
            }
          )),
      })
    else
      setLock(false);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[segments])

  useEffect(() => {
    setSegments(bookingData ? 
      bookingData?.flights?.map((obj,i) => ({
        selected: !i,
        name: obj?.departureLocation + ' - ' + obj?.arrivalLocation,
        from: obj?.departureLocation,
        to: obj?.arrivalLocation,
        date: obj?.departureDate,
        cabin: "",
        cabinOptions: ["Economy", "Business", "FirstClass", "PremiumEconomy"],
        segmentRef: obj?.segmentRef
      }))
    : [])
    //eslint-disable-next-line
  },[order])

  return (
    <div className={Classname.cardContainer}>
      <span className="font-bold text-black">Select journey to change</span>
      <span className="text-sm text-gray-500 mb-2">
        Select the segment to change
      </span>
      {segments?.map((segment, index) => (
        <div
          className={`flex lg:items-center gap-2 w-full px-2 py-2 rounded-md bg-white ${
            segment.selected ? "justify-between" : ""
          }`}
          key={index}
        >
          <div className="flex flex-col lg:flex-row flex-1 items-center gap-2">
            <div
              className={`flex flex-1 items-center min-h-[48px] w-full text-sm lg:max-w-[30%] rounded-md px-4 border-2 ${
                segment.selected ? "bg-primary1/20" : ""
              } border-gray-200 text-sm`}
            >
              {segment.name}
            </div>
            {segment.selected && (
              <div className="w-full lg:max-w-[30%]">
                <CalendarInput1
                  ref={(el) => (calendarRef.current[index] = el)}
                  className="w-full border border-primary/20 rounded-md p-2"
                  value={segment.date || ""}
                  onChange={(value) => {
                    calendarRef.current[index]?.toggle();
                    setSegments(
                      segments.map((p, idx) =>
                        idx === index
                          ? { ...p, date: moment(value?.start || value) }
                          : p
                      )
                    );
                  }}
                  config={{ validDates: [new Date()] }}
                />
              </div>
            )}
            {segment.selected && (
              <div className="w-full lg:max-w-[30%]">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={segment.cabin}
                    placeholder="Select cabin"
                    className="h-10"
                    onChange={(e) => {
                      setSegments(
                        segments.map((p, idx) =>
                          idx === index ? { ...p, cabin: e.target.value } : p
                        )
                      );
                    }}
                  >
                    {segment.cabinOptions.map((cabin, idx) => (
                      <MenuItem value={cabin} key={idx}>
                        {cabin}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            <div
              className={`w-8 h-8 rounded-sm text-xs hidden lg:flex items-center justify-center cursor-pointer ${
                segment.selected
                  ? "bg-primary1 text-white"
                  : "text-[#5A657C] bg-[#CFD9E0]"
              }`}
              onClick={() => {
                setSegments(
                  segments.map((p, idx) =>
                    idx === index ? { ...p, selected: !p.selected } : p
                  )
                );
              }}
            >
              <Check color={"inherit"} fontSize="inherit" />
            </div>
          </div>
          <div
            className={` w-8 h-8 rounded-sm text-xs flex lg:hidden mt-2 items-center justify-center cursor-pointer ${
              segment.selected
                ? "bg-primary1 text-white"
                : "text-[#5A657C] bg-[#CFD9E0]"
            }`}
            onClick={() => {
              setSegments(
                segments.map((p, idx) =>
                  idx === index ? { ...p, selected: !p.selected } : p
                )
              );
            }}
          >
            <Check color={"inherit"} fontSize="inherit" />
          </div>
        </div>
      ))}
    </div>
  );
};

const TicketActionButtons = ({callback}) => {
  const handleCancel = () => {};

  const handleSearch = () => {
    callback && callback()
  };
  return (
    <div className="flex justify-between gap-4 mb-20 mt-10 px-2">
      <button
        onClick={handleCancel}
        className="px-3 py-3 font-bold w-[30%] max-w-[200px] bg-gray-100 rounded-md"
      >
        Cancel
      </button>
      <button
        onClick={handleSearch}
        className="flex flex-1 text-sm font-bold text-white bg-primary1 rounded-md py-3 items-center justify-center max-w-[250px]"
      >
        Search Flights
      </button>
    </div>
  );
};

export default TicketManagementFlightChangeDate;

const Classname = {
  detailInfoContainer:
    "w-full bg-blue-500/10 flex items-start lg:items-center gap-2 mb-4 px-2 lg:px-6 py-2 rounded-md text-sm mt-6",
  detailInfoIcon: "",
  cardContainer:
    "flex flex-1 flex-col w-full p-2 lg:p-4 bg-white mt-6 rounded-md",
  input: "flex flex-1 py-3 rounded-md px-4 border-2 border-gray-200",
};
