import React, { createContext, useEffect, useState } from 'react'
import { getRoles } from '../../../controllers/settings/roles/getRoles';
import moment from 'moment';
import CustomTable from '../../../components/Table/CustomTable';
import Button1 from '../../../components/form/Button1';
import Modal1 from '../../../components/DIsplay/Modal/Modal1';
import RoleForm from './RoleForm';
import Icon from '../../../components/HOC/Icon';
import { deleteRoles } from '../../../controllers/settings/roles/deleteRoles';
import { useSnackbar } from 'notistack';


const ActionContext = createContext();

function ActionCol({params}) {
    return (
        <ActionContext.Consumer >
            {(value) => {
                const {remove,update} = value || {}
                return (
                    <div className='flex gap-2'>
                        <button className='border p-2 ' onClick={() => update(params.row)}>
                            <Icon icon='tabler:edit' />
                        </button>
                        <button className='border p-2 text-red-500' onClick={() => remove(params.row)}>
                            <Icon icon='mdi:delete' />
                        </button>
                    </div>
                )
            }}
        </ActionContext.Consumer>
    )
}

export default function AccountRoles() {
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(false);
    const [openAddRole,setOpenAddRole] = useState(false);
    const [openDelete,setOpenDelete] = useState(false);
    const [openUpdate,setOpenUpdate] = useState(false);
    
    useEffect(() => {
        load();
    },[])

    async function load() {
        setLoading(true);
        const res = await getRoles();
        setLoading(false);
        if(res.return) {
            setData(res?.data?.data?.map(obj => ({...obj,updatedAt: moment(obj?.updatedAt).format('DD/MM/YYYY')})))
        }
    }

    const columns = [
        {field: 'name',headerName: 'NAME'},
        {field: 'permissions',headerName: 'PERMISSIONS',height: 400,
            renderCell: (params) => (
                <div className='max-w-full overflow-auto h-full whitespace-pre-wrap self-start capitalize'>
                    {(params?.value?.map(val => val?.toLowerCase()?.replaceAll('_',' ')) || [])?.join(', ')}
                </div>
            )
        },
        {field: 'updatedAt',headerName: 'Date',
            renderCell: (params) => (
                <div>
                    {params.value}
                </div>
            )
        },
        {field: 'action',headerName: 'Action',
            renderCell: (params) => (
                <ActionCol params={params} />
            )
        }
    ]
    

  return (
    <div className='flex flex-col gap-4'>
        <div className='flex gap-4 justify-end'>
            <Button1 className='!w-auto ' onClick={() => setOpenAddRole(true)}>Add New Role</Button1>
        </div>

        <ActionContext.Provider value={{
            remove: setOpenDelete,
            update: setOpenUpdate,
        }}>
            <CustomTable rows={data} columns={columns} loading={loading} />
        </ActionContext.Provider>

        <Modal1 open={openAddRole} setOpen={setOpenAddRole}>
            <div className='card md:p-10 sm:min-w-[500px]'>
                <RoleForm close={() => setOpenAddRole(false)} callback={() => {load();setOpenAddRole(false)}} />
            </div>
        </Modal1>
        <Modal1 open={openDelete} setOpen={setOpenDelete}>
            <DeleteForm data={openDelete} callback={load} close={() => setOpenDelete(false)} />
        </Modal1>
        <Modal1 open={openUpdate} setOpen={setOpenUpdate}>
            <div className='card md:p-10 sm:min-w-[500px]'>
                <RoleForm update={openUpdate} callback={() => {load();setOpenUpdate(false)}} close={() => setOpenUpdate(false)} />
            </div>
        </Modal1>
    </div>
  )
}

function DeleteForm({close,data,callback}) {
    const {enqueueSnackbar} = useSnackbar();
    const [loading,setLoading] = useState(false);

    async function handleDelete() {
        setLoading(true);
        const res = await deleteRoles(data?._id);
        setLoading(false);
        if(res.return) {
            enqueueSnackbar('Role Removed',{variant: 'success'})        
            close && close();
            callback && callback();
        } else enqueueSnackbar(res.msg,{variant: 'error'})
    }
    
    return (
        <div className='p-10 flex flex-col gap-4'>
            <h5>Are you sure you want to remove role: {data?.name}</h5>
            <div className='flex gap-4'>
                <Button1 className='!w-auto' variant={'outlined'} onClick={() => close && close()}>Close</Button1>
                <Button1 onClick={() => handleDelete()} loading={loading} className='!bg-red-500 text-white'>Delete</Button1>
            </div>
        </div>
    )
}