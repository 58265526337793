export default function sortArrayWithIndices(arr, indices) {
    try {
        // Create a new array to hold the sorted values
        const sortedArray = new Array(arr.length);
    
        // Place each element in the new array according to the indices
        indices.forEach((index, i) => {
        sortedArray[i] = arr[index];
        });
        
        return sortedArray;
    } catch(ex) {
        return arr
    }
  }