import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmHotelBookingModal } from "../../redux/reducers/modalSlice";
import { useState } from "react";
import { useHotels } from "../../hooks/useHotels";
import { URLdecode } from "../../utils/url";
import Loader from "../../utils/loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 600 ? 600 : "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function TicketManagementLoadingModal({ open, setOpen }) {
  const dispatch = useDispatch();
  const { bookRooms } = useHotels();
  const { ticketManagementLoadingModal } = useSelector((state) => state.modal);

  const params = URLdecode();

  const [loading, setLoading] = useState(false);

  const handleOpen = () => dispatch(setConfirmHotelBookingModal(true));
  const handleClose = () => dispatch(setConfirmHotelBookingModal(false));

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen && setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className={Classname.content}>
              <img
                src="/preloader.gif"
                alt=""
                className="w-28 object-contain"
              />
              <p className={Classname.modalDescription}>
                Please wait while we submit your request
              </p>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Classname = {
  container: "flex flex-col font-main",
  content: "flex flex-col items-center px-4 py-4 gap-2",
  modalTitle: "text-xl lg:text-3xl font-bold mb-6 mt-10 text-center",
  modalDescription: "text-gray-600 mb-6 text-center text-sm lg:text-base",
  modalTourName: "font-bold flex flex-col items-center text-center",
  bottomContent:
    "w-full flex items-center justify-center gap-4 py-4 lg:py-10 bg-gray-100 px-4",
  button: "h-16 rounded-md flex items-center justify-center w-60 font-bold",
  backButton: "text-black",
  confirmButton: "text-white bg-primary1",
  wait: "font-bold",
};
