import React, { createContext, useEffect, useState } from 'react'
import getAssignedTasks from '../../controllers/Tasks/getTasks';
import CustomTable from '../../components/Table/CustomTable';
import { useSelector } from 'react-redux';
import getCreatedTasks from '../../controllers/Tasks/getCreatedTasks';
import Icon from '../../components/HOC/Icon';
import Modal1 from '../../components/DIsplay/Modal/Modal1';
import TaskForm from './TaskForm';
import Button1 from '../../components/form/Button1';
import deleteTask from '../../controllers/Tasks/deleteTask';
import { enqueueSnackbar } from 'notistack';
import TextInput from '../../components/form/TextInput';
import { CircularProgress, MenuItem } from '@mui/material';
import updateTaskStatus from '../../controllers/Tasks/updateStatus';

const ActionContext = createContext();

export default function Tasks() {
    const {user} = useSelector(state => state.user.userData)
    const [data,setData] = useState([]);
    const [loadings,setLoadings] = useState({load: false})
    useEffect(() => {
        load()
        //eslint-disable-next-line
    },[])

    async function load() {
        setLoadings({...loadings,load: true})
        let res = {return: 0, msg: 'Something went wrong on our end! 0xRTSK'}
        if(user?.userType === 'Admin')
            res = await getCreatedTasks();
        else
            res = await getAssignedTasks();
        setLoadings({...loadings,load: false})
        if(res.return) {
            setData(res?.data?.data)
        }
    }
    
    const columns = [
        {field: 'taskType', headerName: 'Type'},
        {field: 'note', headerName: 'Description'},
        {field: 'priority', headerName: 'Priority'},
        {field: 'taskStatus', headerName: 'Status',
            renderCell: (params) => (
                <StatusCol params={params} reload={load} />
            )
        },
        {field: 'action', headerName: 'Action',
            renderCell: (params) => (
                <ActionCol params={params} />
            )
        },
    ]

    const [openEdit,setOpenEdit] = useState(false);
    const [openDelete,setOpenDelete] = useState(false);
    
  return (
    <div className='pd-md'>
        <ActionContext.Provider value={{
            handleEdit: setOpenEdit,
            handleDelete: setOpenDelete,
        }}>
            <CustomTable loading={loadings?.load} rows={data} columns={columns} />
        </ActionContext.Provider>

        <Modal1 open={openEdit} setOpen={() => setOpenEdit(false)}>
            <div className='p-4'>
                <TaskForm data={openEdit} callback={() => {load(); setOpenEdit(false)}} />
            </div>
        </Modal1>

        <TaskDeleteModal open={openDelete} close={() => setOpenDelete(false)} callback={() => {load(); setOpenEdit(false)}} />

        
    </div>
  )
}

function TaskDeleteModal({open,close,callback}) {
    const [loading,setLoading] = useState(false);
    async function handleDelete() {
        setLoading(true);
        const res = await deleteTask(open)
        setLoading(false);
        if(res.return) {
            enqueueSnackbar(res.msg,{variant: 'success'})
            callback && callback();
        } else enqueueSnackbar(res.msg,{variant: 'error'})
    }

    
    return (
        <Modal1 open={open} setOpen={() => close && close(false)}>
            <div className='p-4 flex flex-col gap-4'>
                <h5>Are you sure you want to remove this task?</h5>
                <div className='flex gap-4 items-cneter'>
                    <div>
                        <Button1 variant='outlined' onClick={() => close && close()}>Cancel</Button1>
                    </div>
                    <Button1 loading={loading} onClick={() => handleDelete()}>Delete</Button1>
                </div>
            </div>
        </Modal1>
    )
}

function ActionCol({params}) {
    return (
        <ActionContext.Consumer>
            {(value) => {
                const {handleEdit,handleDelete} = value || {}
                return (
                    <div className='flex gap-4 items-center'>
                        <Icon icon='mdi:edit' onClick={() => handleEdit(params?.row)} className='cursor-pointer' />
                        <Icon icon='mdi:delete' onClick={() => handleDelete(params?.row?._id)} className='!text-red-500 cursor-pointer' />
                    </div>
                )
            }}
        </ActionContext.Consumer>
    )
}

function StatusCol({params,reload}) {
    const [loading,setLoading] = useState(false);

    async function handleStatus(status) {
        setLoading(true);
        const res = await updateTaskStatus(params?.row?._id,{status})
        setLoading(false);
        if(res.return) {
            reload && reload();
        } else enqueueSnackbar(res.msg,{variant: 'error'})
    }
    
    return (
        <div className='flex items-center'>
            {loading? 
                <CircularProgress className='!w-6 !h-6' />
            :
                <TextInput select label=''
                    value={params.value}
                    onChange={(ev) => handleStatus(ev.target.value)}
                >
                        {/* ToDo, InProgress, Done, OnHold */}
                        <MenuItem value='ToDo'>ToDo</MenuItem>
                        <MenuItem value='InProgress'>InProgress</MenuItem>
                        <MenuItem value='Done'>Done</MenuItem>
                        <MenuItem value='OnHold'>OnHold</MenuItem>
                </TextInput>
            }
        </div>
    )
}