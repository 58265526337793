import { useState } from "react";
import Icon from "../../../../components/HOC/Icon";
import { getAgeType } from "../../../../utils/booking/getAgeType";
import { useSnackbar } from "notistack";
import { Modal3 } from "../../../../components/DIsplay/Modal/Modal3";

export const Passengers = ({order,page}) => {
    const orderData = order?.booking?.flightBooking?.at(0);
    const [openDetail,setOpenDetail] = useState(false);
  
  
  
    const handleItinerary = () => {};
    return (
      <div className="w-full flex flex-col mt-6">
        <span className="font-bold text-xl mb-3">Passenger</span>
        {orderData?.travelers
          ?.map((passenger, index) => (
            <div className="flex w-full flex-col shadow-md mb-6" key={index}>
              <div className="flex flex-col lg:flex-row items-end bg-white rounded-t-lg border-b">
                <div className="items-center flex flex-wrap gap-4 px-4 py-2 flex-1 w-full ">
                  <div className="flex gap-4 items-center">
                    <span className="min-w-[24px] h-6 rounded-md text-white font-bold items-center justify-center flex text-sm bg-primary1">
                      {index + 1}
                    </span>
                    <div className="flex flex-col gap-2">
                      <span className="text-sm lg:text-base font-bold text-primary1">
                        {passenger?.firstName} {passenger?.middleName} {passenger?.lastName}
                        {/* Ike/ John Chinedu */}
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-1 items-start">
                    <span className="text-[#032D5F] capitalize">{getAgeType(passenger?.travelerType)} {passenger?.gender}</span>
                  </div>
                  <button onClick={() => setOpenDetail(passenger)} className="gap-2 text-sm flex-1 flex justify-end items-center text-primary/60">
                    Details
                    <Icon icon='cuida:open-in-new-tab-outline' className='!w-4 !h-4 !text-theme1' />
                  </button>
                </div>
                {order?.passengerIds?.includes(passenger?.id) && (
                  <div className="text-primary1 uppercase bg-primary1/10 rounded-md py-1 px-3 w-fit text-xs my-1 mr-2">
                    {page} change requested
                  </div>
                )}
              </div>
              <div className="flex flex-col w-full shadow-md">
                <div className="p-4 bg-white flex flex-wrap gap-y-4 lg:gap-4 w-full">
                  <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col gap-2">
                    <span className="text-sm lg:text-base text-[#818083] ">
                      Date of birth
                    </span>
                    <span className=" text-sm">{passenger?.birthDate}</span>
                  </div>
                  <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col gap-2">
                    <span className="text-sm lg:text-base text-[#818083] ">
                      Passport No
                    </span>
                    <span className="text-sm">{passenger?.document?.number}</span>
                  </div>
                  <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col gap-2 lg:items-center">
                    <span className="text-sm lg:text-base text-[#818083] ">
                      Passport Expiry
                    </span>
                    <span className="text-sm">{passenger?.document?.expiryDate}</span>
                  </div>
                  <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col gap-2">
                    <span className="text-sm lg:text-base text-[#818083] ">
                      Nationality
                    </span>
                    <span className="text-sm">{passenger?.document?.nationality}</span>
                  </div>
                  <div className="flex lg:flex-1 sm:w-1/2 lg:w-fit flex-col gap-2">
                    <span className="text-sm lg:text-base text-[#818083] ">
                      Ticket No
                    </span>
                    {passenger?.ticketNumbers?.map((ticket) => (
                      <Copy key={ticket} value={ticket} />
                    ))}
                  </div>
                  <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col gap-2 lg:items-center">
                    <span className="text-sm lg:text-base text-[#818083] ">
                      Itinerary
                    </span>
                    <img
                      src="/IconItinerary.svg"
                      alt=""
                      className="w-4 h-4 object-contain cursor-pointer"
                      onClick={() => handleItinerary()}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
          <Modal3 open={openDetail} setOpen={setOpenDetail} title='Passenger Detail'>
            <div>
            <div className="flex flex-col w-full shadow-md p-4">
                <div className="p-4 bg-white flex flex-wrap gap-y-4 w-full">
                  <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col justify-between gap-2">
                    <span className="!text-xs uppercase lg:text-base flex-1 items-center bg-theme1/10 p-2 px-4 text-[#818083] ">
                      Name 
                      <div>(Last name/ Given name)</div>
                    </span>
                    <span className="text-sm px-4 whitespace-nowrap">{openDetail?.firstName} {openDetail?.middleName} {openDetail?.lastName}</span>
                  </div>
                  <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col justify-between gap-2">
                    <span className="!text-xs uppercase lg:text-base flex-1 flex items-center bg-theme1/10 p-2 px-4 text-[#818083] ">
                      Gender
                    </span>
                    <span className="text-sm px-4 whitespace-nowrap">{openDetail?.gender}</span>
                  </div>
                  <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col justify-between gap-2">
                    <span className="!text-xs uppercase lg:text-base flex-1 flex items-center bg-theme1/10 p-2 px-4 text-[#818083] ">
                      Date of birth
                    </span>
                    <span className="text-sm px-4 whitespace-nowrap">{openDetail?.birthDate}</span>
                  </div>
                  <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col justify-between gap-2">
                    <span className="!text-xs uppercase lg:text-base flex-1 flex items-center bg-theme1/10 p-2 px-4 text-[#818083] ">
                      ID No
                    </span>
                    <span className="text-sm px-4 whitespace-nowrap">{openDetail?.document?.number}</span>
                  </div>
                  <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col justify-between gap-2">
                    <span className="!text-xs uppercase lg:text-base flex-1 flex items-center bg-theme1/10 p-2 px-4 text-[#818083] ">
                      Nationality
                    </span>
                    <span className="text-sm px-4 whitespace-nowrap">{openDetail?.document?.nationality}</span>
                  </div>
                  <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col justify-between gap-2">
                    <span className="!text-xs uppercase lg:text-base flex-1 flex items-center bg-theme1/10 p-2 px-4 text-[#818083] ">
                      ID Type
                    </span>
                    <span className="text-sm px-4 whitespace-nowrap">{openDetail?.document?.documentType}</span>
                  </div>
                  <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col justify-between gap-2 lg:items-center">
                    <span className="!text-xs uppercase lg:text-base flex-1 flex items-center bg-theme1/10 p-2 px-4 text-[#818083] ">
                      ID Expiry Date
                    </span>
                    <span className="text-sm px-4 whitespace-nowrap">{openDetail?.document?.expiryDate}</span>
                  </div>
                  <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col justify-between gap-2 lg:items-center">
                    <span className="!text-xs uppercase lg:text-base flex-1 flex items-center bg-theme1/10 p-2 px-4 text-[#818083] ">
                      Frequent flyer program
                    </span>
                    <span className="text-sm flex-1">{openDetail?.document?.frequentFlyerNo}</span>
                  </div>
                  <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col justify-between gap-2">
                    <span className="!text-xs uppercase lg:text-base flex-1 flex items-center bg-theme1/10 p-2 px-4 text-[#818083] ">
                      Ticket No
                    </span>
                    <div className="h-[20px]">
                      {openDetail?.ticketNumbers?.map((ticket) => (
                        <Copy key={ticket} value={ticket} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>            
            </div>
          </Modal3>
      </div>
    );
  };
  
  const Copy = ({ value }) => {
    const { enqueueSnackbar } = useSnackbar();
    const handleCopy = () => {
      try {
        navigator.clipboard.writeText(value);
        enqueueSnackbar("Copied to clipboard!", { variant: "success" });
      } catch (error) {
        enqueueSnackbar("Failed to copy to clipboard!", { variant: "error" });
      }
    };
    return (
      <div
        className="flex gap-1 items-center mb-2 cursor-pointer"
        onClick={handleCopy}
      >
        <span className="text-xs text-primary1">{value}</span>
        <img src="/IconCopy.svg" alt="" />
      </div>
    );
  };
  