import queryString from "query-string";

export const URLencode = (params) => {
  const stringified = queryString.stringify(params);

  return stringified;
};

export const URLdecode = () => {
  const qString = window.location.search;

  const params = queryString.parse(qString);

  return params;
};
