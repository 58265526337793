import React, { useEffect, useState } from 'react'
import TextInput from '../../../components/form/TextInput'
import { CircularProgress, MenuItem } from '@mui/material'
import Icon from '../../../components/HOC/Icon'
import Button1 from '../../../components/form/Button1'
import { createRoles } from '../../../controllers/settings/roles/createRoles'
import { useSnackbar } from 'notistack'
import { getRolesPermissions } from '../../../controllers/settings/roles/getRolesPermissions'
import { updateRoles } from '../../../controllers/settings/roles/updateRoles'
import { RolesData } from '../../../data/ENUM/Roles'
import Checkbox from '../../../components/form/Checkbox'


const initData = {
    name: '',
    permissions: []
}

const ToggleButton = ({data,callback}) => {
    const [selected,setSelected] = useState(data?.selected || false);

    useEffect(() => {
        setSelected(data?.selected)
    },[data?.selected])

    function handleSelect() {
        setSelected(!selected);
        callback && callback(!selected)
    }
    
    return (
        <div className={`border border-primary/20 capitalize text-sm rounded-md p-2 flex items-center gap-2 cursor-pointer
            transition-all
            hover:shadow-md ${selected ? 'bg-theme1/50 !text-secondary border-theme1' : ''}
        `} onClick={() => handleSelect()}>
            {data?.value?.toLowerCase()?.replaceAll('_', ' ')}
            <span>
                {selected ? 
                <Icon icon='mdi:tick' className='!w-4 !h-4' />
                :
                <Icon icon='mdi:close' className='!w-4 !h-4' />
                }
            </span>
        </div>
    )
}

export default function RoleForm({update,close,callback}) {
    const [data,setData] = useState(initData)
    const [loading,setLoading] = useState(false);
    const [product,setProduct] = useState('All');
    const [loadingPerm,setLoadingPerm] = useState(false);

    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        load();
        //eslint-disable-next-line
    },[update])

    async function load() {
        setLoadingPerm(true);
        const res = await getRolesPermissions();
        setLoadingPerm(false);
        if(res.return) {
            let data = initData;
            if(update) {
                data.name = update?.name;
                data.permissions = res?.data?.map(obj => update?.permissions?.includes(obj?.value) ? ({...obj,selected:true}) : ({...obj,selected:false}))
            } else {
                data.permissions = res?.data?.map(obj => ({...obj,selected:false}))
            }
            setData(data)
        }
    }

    async function handleSubmit(ev) {
        ev?.preventDefault();

        const reqBody = {...data};
        reqBody.permissions = reqBody?.permissions?.filter(obj => obj?.selected)?.map(obj => obj?.value)

        setLoading(true);
        let res = {return: 0, msg: 'Something went wrong! Please contact support 0XROLEREQUNK'}
        if(update)
            res = await updateRoles(update?._id,reqBody);
        else res = await createRoles(reqBody);
        setLoading(false);
        if(res.return) {
            enqueueSnackbar('Role Created',{variant: 'success'})            
            callback && callback()
            setData(initData)
        } else enqueueSnackbar(res.msg,{variant: 'error'})
    } 

    function handleSelections(obj) {
        let permissions = data?.permissions?.map(obj => obj);
        if(Array.isArray(obj))
            permissions = permissions?.map(role => {
                let change = obj?.find(perm => perm.value === role.value)
                return change || role;
            })
        else permissions = permissions?.map(role => role.value === obj?.value ? obj : role)

        // console.log('setting data',permissions?.map(obj => ({value: obj.value,selected: obj?.selected})))
        setData({...data,permissions})
    }

    const products = Array.from(new Set(RolesData?.map(obj => obj?.product)))

    // console.log(update,data?.permissions?.map(obj => ({value:obj.value,selected: obj?.selected})))
    // console.log(data?.permissions?.map(obj => ({name: obj.name,value: obj.selected})))
  return (
    <form onSubmit={handleSubmit} className='flex flex-col gap-4 max-w-full w-[500px] '>
        <h5>{!update ? 'Add new role' : 'Update role'}</h5>
        
        <TextInput label='Title' placeholder={'e.g Ticketing Officer'} 
            value={data.name}
            onChange={(ev) => setData({...data,name: ev.target.value})}
        />

        <TextInput select label={'Select Product'} value={product} onChange={(ev) => setProduct(ev?.target?.value)}>
            {products?.map((product,i) => (
                <MenuItem value={product} key={i}>{product}</MenuItem>
            ))}
        </TextInput>

        {!loadingPerm ?
            Object.entries(data?.permissions?.filter(obj => product ? (product?.toLowerCase() === 'all' ? true : obj.product === product) : true)?.reduce((acc,cur) => {
                let group = acc
                group[cur?.category||'Other'] = [...(group[cur?.category] || []),cur];
                return group
            },{}))?.map(([type,objs],i) => (
                <div key={i} className='flex flex-col gap-3'>
                    <Checkbox labelClassName='w-auto self-start'
                      onClick={(ev) => handleSelections(objs.map(obj => ({...obj,selected:ev.target.checked})))} >
                        <h6>{type}</h6>
                    </Checkbox>
                    <div className='flex flex-wrap gap-4'>
                        {objs?.map((obj,i) => (
                            <ToggleButton key={i} data={obj} callback={(selected) => handleSelections({...obj,selected})} />
                        ))}
                    </div>
                </div>
            ))
        : <div className='flex justify-center'><CircularProgress className='!w-4 !h-4' /></div>
        }
        <hr />
        <div className='flex gap-4 flex-1 flex-wrap-reverse'>
            <Button1 onClick={() => close && close()} variant='outlined' className='!w-auto'>Cancel</Button1>
            <div className='flex-1'>
                <Button1 type='submit' loading={loading}>{!update ? 'Add Role':'Update Role'}</Button1>
            </div>
        </div>
    </form>
  )
}
