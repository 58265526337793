import { createSlice } from "@reduxjs/toolkit";

let defaultData = {
  managementTab: "create",
};
let initialState = {
  ...defaultData,
};

export const tabSlice = createSlice({
  name: "tabSlice",
  initialState,
  reducers: {
    setManagementTab: (state, action) => {
      state.managementTab = action.payload;
    },
  },
});

export const { setManagementTab } = tabSlice.actions;

export default tabSlice.reducer;
