import React from "react";
import useOrders from "../../hooks/useOrders";
import { useSelector } from "react-redux";

function OrderStatusFilterSelection() {
  const { ticketStatusType } = useSelector((state) => state.order);
  const { getTicketStatusFilterTypes } = useOrders();

  return (
    <div className="w-full flex flex-col pt-4">
      <div className="flex w-full items-center gap-4 bg-[#F2EFEF] rounded-lg overflow-x-scroll scroll-hide">
        {getTicketStatusFilterTypes().map(({ name, onClick }) => (
          <button
            key={name}
            onClick={onClick}
            className={`h-12 flex-1 flex items-center justify-center gap-2 px-4 text-sm font-bold rounded-lg ${
              ticketStatusType === name
                ? "text-white bg-primary1"
                : "text-[#818083] bg-[#F8F8F8]"
            }`}
          >
            <span className="whitespace-nowrap">{name}</span>
          </button>
        ))}
      </div>
    </div>
  );
}

export default OrderStatusFilterSelection;
