import { useDispatch } from "react-redux";

export default function useLocalStorage() {
  const dispatch = useDispatch();
  const saveTours = (data) => {
    localStorage.setItem("tours", JSON.stringify(data));
  };

  const loadTours = () => {
    const data = localStorage.getItem("tours");
    if (data) {
      return JSON.parse(data);
    } else {
      return data;
    }
  };

  return {
    saveTours,
    loadTours,
  };
}
