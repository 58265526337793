import moment from "moment";
import { getCurrencySymbol } from "../../features/utils/countires";
import { flightStatusMap } from "../../pages/Orders/OrdersData";

export function templateFlightOrderData(obj) {
  // let data = mergeRecursive({...obj},ordersDataTemplate)
  let data = {
    ...obj,
    id: obj?._id,
    bookingId: obj?.bookingId,
    date: moment(obj?.createdAt)?.format("DD, MMM"),
    name: obj?.account?.firstName + " " + obj?.account?.lastName,
    provider: obj?.flightBooking?.at(0)?.supplier,
    type: "Flight",
    currency: getCurrencySymbol(obj?.flightBooking?.at(0)?.currency),
    amount: obj?.flightBooking?.at(0)?.grandTotal,
    commission: obj?.flightBooking?.at(0)?.expectedCommission,
    updatedDate: moment(obj?.updatedAt)?.format("DD/MM/YYYY"),
    bookRef: obj?.flightBooking?.at(0)?.pnr,
    status: flightStatusMap(obj?.flightBooking?.at(0)?.status),
  };

  return data;
}

export function templateHotelOrderData(obj) {
  // let data = mergeRecursive({...obj},ordersDataTemplate)
  let data = {
    ...obj,
    id: obj?._id,
    bookingId: obj?.bookingId,
    date: moment(obj?.createdAt)?.format("DD, MMM"),
    name:
      obj?.holdBookingRes?.guests?.at(0)?.first_name +
      " " +
      obj?.holdBookingRes?.guests?.at(0)?.last_name,
    provider: obj?.supplier,
    type: "Stay",
    currency: getCurrencySymbol(obj?.holdBookingRes?.currency),
    amount: obj?.grandTotal,
    commission: obj?.commission,
    updatedDate: moment(obj?.updatedAt)?.format("DD/MM/YYYY"),
    bookRef: obj?.bookingId || "-----------",
    status: flightStatusMap(obj?.status),
  };

  return data;
}

export function templateTourOrderData(obj) {
  let name = "";
  let bookingQuestions = obj?.bookingQuestionAnswers;
  if (bookingQuestions) {
    let firstName = bookingQuestions?.find(
      (question) => question.question === "FULL_NAMES_FIRST"
    )?.answer;
    let lastName = bookingQuestions?.find(
      (question) => question.question === "FULL_NAMES_LAST"
    )?.answer;
    if (!firstName && !lastName) {
      name = "";
    } else {
      name = firstName + " " + lastName;
    }
  }
  let data = {
    ...obj,
    id: obj?._id,
    bookingId: obj?.itemRef,
    date: moment(obj?.createdAt)?.format("DD, MMM"),
    name: name,
    provider: obj?.supplier,
    type: "Tour",
    currency: getCurrencySymbol(obj?.holdBookingRes?.currency),
    amount: obj?.grandTotal,
    commission: obj?.commission,
    updatedDate: moment(obj?.updatedAt)?.format("DD/MM/YYYY"),
    bookRef: obj?.itemRef || "-----------",
    status: flightStatusMap(obj?.status),
  };

  return data;
}
