import { URLdecode } from "../../utils/url";

const TicketManagementFlightRefundStepsHeader = ({ step }) => {
  const params = URLdecode();
  console.log(params);
  const choices = [
    { name: "Choose reason", onClick: () => {} },
    { name: "Confirm refund", onClick: () => {} },
    { name: "Confirmation", onClick: () => {} },
  ];

  return (
    <div className="flex gap-2 lg:gap-4 p-2 lg:p-4 bg-[#D9D9D9] flex-wrap mt-3 rounded-md">
      {choices.map(({ name, onClick, selected }, index) => (
        <button
          key={index}
          className={`flex flex-1 py-2 lg:py-3 px-2 rounded-md font-bold ${
            step === index + 1
              ? "text-white bg-[#4E5662]"
              : "text-[#818083] bg-white"
          } items-center justify-center text-xs lg:text-base`}
          onClick={onClick}
        >
          {name}
        </button>
      ))}
    </div>
  );
};

export default TicketManagementFlightRefundStepsHeader;
