import { FormControl, MenuItem, Select } from "@mui/material";
import { useState } from "react";

export const SelectReason = ({callback}) => {
    const [reason, setReason] = useState("");
  
    const reasons = [
      {key: "Voluntary", value: "Voluntary"},
      {key: "ScheduleChange", value: "Flight reschedule/ Cancellation"},
      {key: "Illness", value: "Re-issue due to medical reasons"},
      {key: "VisaRefusal", value: "Re-issue due to visa refusal"},
      {key: "Personal", value: "Other reasons"},
    ];
  
    function handleChange(val) {
      setReason(val)
      callback && callback(val)
    }
  
    return (
      <div className={Classname.cardContainer}>
        <span className="font-bold text-black">Select a reason for change</span>
        <div className={Classname.detailInfoContainer}>
          <img src="/IconInfo.svg" alt="" className={Classname.detailInfoIcon} />
          <span className="font-normal">
            There are two types of changes: Voluntary and Involuntary changes.
            Voluntary changes refer to changes that are made in accordance to the
            fare rules set by airlines. These include modifications that are
            allowed within the allowed guideline. Any other change is considered
            involuntary. Involuntary changes require a document to support reason
            for change. The document would serve as an evidence for reasons behind
            the change and help ensure a smooth processing.
          </span>
        </div>
        <div className="max-w-sm">
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={reason}
              placeholder="Select a reason"
              onChange={(e) => handleChange(e?.target?.value)}
              displayEmpty
            >
               <MenuItem value="" disabled>
                Select a reason
              </MenuItem>
              {reasons.map((r, index) => (
                <MenuItem key={index} value={r.key}>
                  {r.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    );
  };
  
  const Classname = {
    detailInfoContainer:
      "w-full bg-blue-500/10 flex items-start lg:items-center gap-2 mb-4 px-2 lg:px-6 py-2 rounded-md text-sm mt-6",
    detailInfoIcon: "",
    cardContainer:
      "flex flex-1 flex-col w-full p-2 lg:p-4 bg-white mt-6 rounded-md",
    input: "flex flex-1 py-3 rounded-md px-4 border-2 border-gray-200",
  };
  