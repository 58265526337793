import fetchServer from "../../fetchServer";

export async function updateRoles(id,data) {
  let result = {return: 0, msg: "Something went wrong creating Role!"};

  await fetchServer({method: "PATCH",url: `/main/v1/role/${id}`,data})
    .then((res) => {
      if (res?.data && !res?.data?.error) {
        result = { return: 1, msg: "Successful", data: res?.data?.data };
      } else if (res?.data?.error) result["msg"] = res.data.error;
    })
    .catch((err) => {
      console.log("Network Error!");
    });

  return result;
}
