export const taskData = {
    "note": "",
    "taskType": "Ticketing", //Ticketing, ChangeOrder, Void, Refund, Support, Other
    "taskStatus": "ToDo", // ToDo, InProgress, Done, OnHold
    "priority": "Medium", //Low, Medium, High, Critical
    "assignee": ""
    //optional depending on the task
    // "dueDate": "2024-10-28",
    // "ticketManagementId": "",
    // "flightBookingId": "",
    // "refundTicketManagementId": "",
    // "voidTicketManagementId": ""
    // "changeOrderManagementId": ""
  }
