import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  hotel: null,
  hotelAvailability: null,
  hotels: [],
  hotelBookings: [],
};

export const hotelDataSlice = createSlice({
  name: "hotelDataSlice",
  initialState,
  reducers: {
    setHotel: (state, action) => {
      state.hotel = action.payload;
    },
    setHotels: (state, action) => {
      state.hotels = action.payload;
    },
    setHotelAvailability: (state, action) => {
      state.hotelAvailability = action.payload;
    },
    setHotelBookings: (state, action) => {
      state.hotelBookings = action.payload;
    },
  },
});

export const { setHotel, setHotels, setHotelAvailability, setHotelBookings } =
  hotelDataSlice.actions;

export default hotelDataSlice.reducer;
