import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  tour: null,
  tourAvailability: null,
  activities: [],
  numberOfAdults: 1,
  numberOfChildren: 0,
  tourBookings: [],
  cancelQuote: null,
  tourGroupQuestions: [],
  tourTouristQuestions: [],
};

export const tourDataSlice = createSlice({
  name: "tourDataSlice",
  initialState,
  reducers: {
    setTourActivity: (state, action) => {
      state.tour = action.payload;
    },
    setActivities: (state, action) => {
      state.activities = action.payload;
    },
    setTourAvailability: (state, action) => {
      state.tourAvailability = action.payload;
    },
    setNumberOfAdults: (state, action) => {
      state.numberOfAdults = action.payload;
    },
    setNumberOfChildren: (state, action) => {
      state.numberOfChildren = action.payload;
    },
    setTourBookings: (state, action) => {
      state.tourBookings = action.payload;
    },
    setCancelQuote: (state, action) => {
      state.cancelQuote = action.payload;
    },
    setTourGroupQuestions: (state, action) => {
      state.tourGroupQuestions = action.payload;
    },
    setTourTouristQuestions: (state, action) => {
      state.tourTouristQuestions = action.payload;
    },
  },
});

export const {
  setTourActivity,
  setActivities,
  setTourAvailability,
  setNumberOfAdults,
  setNumberOfChildren,
  setTourBookings,
  setCancelQuote,
  setTourGroupQuestions,
  setTourTouristQuestions,
} = tourDataSlice.actions;

export default tourDataSlice.reducer;
