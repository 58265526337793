import sortArrayWithIndices from "../features/utils/sortWithIndice";

export default function useOrdersSort() {
    function sortFlightOrders(data,filter) {
        const temp = [...(data||[])]

        function sortIndices(arr) {
            const indices = arr.map((_, index) => index);

            indices.sort((a, b) => {
                const aVal = arr[a]?.at(filter?.index)?.value;
                const bVal = arr[b]?.at(filter?.index)?.value;

                // console.log(aVal,bVal)
                if(filter?.type === 'desc')
                    return bVal?.toString()?.localeCompare(aVal?.toString(),undefined,{numeric: true})
                else
                    return aVal?.toString()?.localeCompare(bVal?.toString(),undefined,{numeric: true})
            });
          
            return indices;
          }

        
        const indices = sortIndices(temp)
        return [sortArrayWithIndices(temp,indices),indices]
    }

    return {
        sortFlightOrders,
    }
}