import { Link, useParams } from "react-router-dom";
import { URLdecode } from "../../../../utils/url";
import { useRef, useState } from "react";
import cancelChangeRequest from "../../../../controllers/booking/postTicketing/change/cancelChangeRequest";
import cancelRefundRequest from "../../../../controllers/booking/postTicketing/refund/cancelRefundRequest";
import cancelVoidRequest from "../../../../controllers/booking/postTicketing/void/cancelVoidRequest";
import { enqueueSnackbar } from "notistack";
import jsPDF from "jspdf";
import { shareFlightBookingPDF } from "../../../../controllers/Flight/shareFlightBookingPDF";
import cancelAndRefundBooking from "../../../../controllers/booking/cancelAndRefundBooking.js";
import { StatusCard } from "../../../Orders/Flight/Booked/StatusCard";
import { ArrowForward } from "@mui/icons-material";
import CustomMenu from "../../../../components/utils/CustomMenu";
import Icon from "../../../../components/HOC/Icon";
import { OrderMenus } from "../../../Orders/OrdersData";
import Button1 from "../../../../components/form/Button1";
import Modal1 from "../../../../components/DIsplay/Modal/Modal1";
import PaymentMethod from "../../../../components/flight/PaymentMethod";
import TicketIssue from "../../../Orders/IssueTicket";
import EmailExport from "../../../Orders/Flight/Booked/EmailExport";
import RadioGroup from "../../../../components/form/RadioGroup";
import FlightDoc from "../../../Orders/Flight/Booked/FlightDoc";
import { Modal3 } from "../../../../components/DIsplay/Modal/Modal3";
import html2canvas from "html2canvas";
import moment from "moment";

export const OrderDetail = ({order,reload,page,bookingData}) => {
    const { id } = useParams();
  
    const params = URLdecode();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openExport, setOpenExport] = useState(false);
    const [openEmailExport, setOpenEmailExport] = useState(false);
    const [openPDFExport, setOpenPDFExport] = useState(false);
    const [openInsurance, setOpenInsurance] = useState(false);
    const [openIssueTicket, setOpenIssueTicket] = useState(false);
    const [openHoldBooking, setOpenHoldBooking] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);
    const [openCancelOrder, setOpenCancelOrder] = useState(false);
    const [openCancelRequest, setOpenCancelRequest] = useState(false);
    const [newStatus,setNewStatus] = useState();
    const [selectedOption, setSelectedOption] = useState();
    const componentRef = useRef();
    const [loadings,setLoadings] = useState({cancel: false});
  
  
    const orderData = order?.booking?.flightBooking?.at(0);
    const ticketData = order?.ticketQueue?.at(0) || {ticketNo: order?.ticketNo};
  
  
    let orderType = orderData ? "flight" : "";
  
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleExport = () => {};
  
    const handleCancel = async () => {
      setLoadings({...loadings,cancel: true})
      let res = {return: 0,msg: 'Error 0xCERNF'}
      if(page === 'Change')
        res = await cancelChangeRequest(id)
      else if(page === 'refund')
        res = await cancelRefundRequest(id);
      else if(page === 'Void')
        res = await cancelVoidRequest(id);
      setLoadings({...loadings,cancel: false})
      if(res.return) {
        enqueueSnackbar('Request Cancelled',{variant: 'success'})
        reload && reload();
      } else
        enqueueSnackbar(res.msg,{variant: 'error'})
    };
  
    const handleRefund = () => {};
  
    const handleTicket = () => {};
  
    let tripType = orderData?.flights?.length > 1 ? '' : 'One way'
    if(orderData?.flights?.length > 1) {
      if(orderData?.flights?.at(0)?.departureLocation === orderData?.flights?.at(-1)?.arrivalLocation)
        tripType = 'Round trip'
    }
  
    function handleOption() {
      if (selectedOption === "email") setOpenEmailExport(true);
      else if (selectedOption === "pdf") {
        setOpenPDFExport(true);
        setTimeout(() => 
          // handlePrint()
          handlePDFExport()
        , 1000);
      }
  
      setOpenExport(false);
    }
  
    function handlePDFExport(callback) {
      const component = document.getElementById("flightDoc");
  
      const desiredWidth = 795; // Set your desired width here
  
      const scaleFactor = desiredWidth / component.offsetWidth;
  
      const desiredHeight = component.offsetHeight * scaleFactor;
  
      // component.style.width = `${desiredWidth}px`;
      // component.style.height = `${desiredHeight}px`;
  
      const segments = Array.from(
        { length: Math.ceil(component?.offsetHeight / desiredHeight) + 1 },
        (_, index) => index * desiredHeight
      );
      console.log(segments,component?.offsetHeight,desiredHeight)
      const pdf = new jsPDF();
  
      function captureSegmentScreenshot(index) {
        if (index >= segments.length) {
          if (callback) callback(pdf.output("blob"));
          else pdf.save(order?.booking?.bookingId + ".pdf");
  
          component.style.width = "auto";
          component.style.height = "auto";
  
          return;
        }
  
        if (index) pdf.addPage();
  
        setTimeout(
          () =>
            html2canvas(component, { useCORS: true, allowTaint: false }).then((canvas) => {
              const imgData = canvas.toDataURL("image/png");
  
              const pdfWidth = pdf.internal.pageSize.getWidth();
              const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
  
              const xPos =
                (pdfWidth - canvas.width * (pdfHeight / canvas.height)) / 2;
              const yPos = index * (desiredWidth - 500);
  
              pdf.addImage(
                imgData,
                "PNG",
                xPos,
                -yPos,
                canvas.width * (pdfHeight / canvas.height),
                pdfHeight
              );
  
              captureSegmentScreenshot(index + 1);
            }),
          10
        );
      }
  
      captureSegmentScreenshot(0);
    }
  
    async function sendPDF(pdf, datas) {
      const bookingId = order?.booking?.bookingId;
      const date = order?.booking?.createdDate;
      const departure =
        order?.orderDetail?.offers?.at(0)?.directions?.at(0)?.at(0)?.departure
          ?.city || orderData?.flights[0]?.departureLocation;
      const arrival =
        order?.orderDetail?.offers?.at(-1)?.directions?.at(0)?.at(0)?.arrival
          ?.city || orderData?.flights[0]?.arrivalLocation;
  
      const formData = new FormData();
  
      datas?.map((data) => formData?.append("to", data.email));
      formData.append("subject", "Flight Booking Details");
      formData.append(
        "body",
        "Booking details of " + departure + " to " + arrival
      );
      formData.append("pdf", pdf, bookingId + "--" + date + ".pdf");
  
      enqueueSnackbar("Sending... (This might take a while if the pdf is large)");
      const res = await shareFlightBookingPDF(formData);
      if (res.return) {
        enqueueSnackbar(res?.msg, { variant: "success" });
      } else enqueueSnackbar(res?.msg, { variant: "error" });
    }
    function handleEmailExport(data) {
      setOpenPDFExport(true);
  
      setTimeout(() => handlePDFExport((pdf) => sendPDF(pdf, data)), 500);
      setOpenEmailExport(false);
    }
  
    async function handleCancelAndRefund() {
      setLoadings({...loadings,cancel: true})
      const res = await cancelAndRefundBooking(id)
      setLoadings({...loadings,cancel: false})
      if(res.return) {
        enqueueSnackbar(res.msg,{variant: 'success'})
        reload && reload();
      } else enqueueSnackbar(res.msg,{variant: 'error'})
    }
  
    const VoidDisplay = () => {
      return null;
    }
    
    return (
      <div className="flex flex-col w-full shadow-md sm:mt-4">
        <StatusCard data={orderData} ticketData={ticketData}
          {...{
            pay: () => setOpenPayment(orderData?._id),
            cancelRequest: () => setOpenCancelRequest(orderData?._id),
            cancel: () => setOpenCancelOrder(orderData?._id),
            issueTicket: () => setOpenIssueTicket(orderData),
            hold: () => setOpenHoldBooking(orderData),
            reload: () => reload && reload(),
            setNewStatus,
          }}
        />
  
        <div className="w-full flex flex-col lg:flex-row bg-white rounded-t-md items-center justify-between p-4 gap-4 border-[#1E61DC]">
          <div className="flex flex-wrap w-full items-center flex-1 gap-3">
            <div className="hidden sm:block text-[#1E61DC] text-sm font-bold px-4 py-2 rounded-md bg-[#DFEAFF]">
              {tripType}
            </div>
            <div className="flex flex-wrap flex-1 gap-x-4 gap-y-2 items-center text-xl font-bold">
              <span>{orderData?.flights?.at(0)?.departureLocationName}</span>
              <ArrowForward />
              <span>{orderData?.flights?.at(0)?.arrivalLocationName}</span>
            </div>
          </div>
          {page === 'void' ? 
            <VoidDisplay />
          :
            <div className="flex flex-wrap sm:flex-nowrap gap-y-2 w-full flex-1 lg:justify-end">
              {!page ? 
                <button className={Classname.button} 
                onClick={() => setOpenExport(true)}
                >
                  Export itinerary
                </button>
              :null}
              {!page ? 
                <CustomMenu
                  element={
                    <button className="!min-w-[180px] !text-primary flex gap-2 !bg-primary/10 p-2 px-3 whitespace-nowrap">
                      Manage this order
                      <Icon icon="mdi:arrow-down-drop" />
                    </button>
                  }
                >
                  <OrderMenus
                    data={{ id, status: newStatus || orderData?.status, orderType }}
                    inDetail
                    actions={{
                      addInsurance: (id) => setOpenInsurance(id),
                      pay: () => setOpenPayment(orderData?._id),
                      cancelOrder: () => setOpenCancelOrder(orderData?._id),
                      issueTicket: () => setOpenIssueTicket(orderData),
                    }}
                    />
                </CustomMenu>
                :     
                <div className="">
                  {order?.status === 'Pending' && page !== 'confirm' ? 
                    <Button1 loading={loadings.cancel} className="!bg-primary/10 !text-primary/70 rounded-md !p-2 !px-4" onClick={handleCancel}>Cancel request</Button1>
                  :null}
                </div>
              }
            </div>
          }
        </div>
        <BookInfo {...{bookingData}} flightBooking={orderData} order={order} page={page} />
        <Modal1 open={openPayment} setOpen={setOpenPayment}>
          <PaymentMethod
            callback={() => {
              reload && reload();
              setOpenPayment(false);
            }}
            flightBookingId={openPayment}
            hide={["booklater"]}
            expand
          />
        </Modal1>
        <Modal1 open={openIssueTicket} setOpen={setOpenIssueTicket}>
          <TicketIssue
            data={openIssueTicket}
            callback={() => {
              reload && reload();
              setOpenIssueTicket(false);
            }}
            close={() => setOpenIssueTicket(false)}
          />
        </Modal1>
  
        <Modal1 open={openEmailExport} setOpen={setOpenEmailExport}>
          <EmailExport callback={handleEmailExport} />
        </Modal1>
  
        <Modal1 open={openExport} setOpen={setOpenExport}>
          <div className="card p-10 flex flex-col gap-4">
            <h5 className="self-center">Export Order</h5>
            <RadioGroup
              options={[
                {
                  title: "Export PDF to Email",
                  description: "Select the option to export the order via email",
                  value: "email",
                },
                {
                  title: "Download PDF",
                  description: "Select the option to export the order via PDF",
                  value: "pdf",
                },
              ]}
              className="flex flex-col gap-4"
              radioClass="!items-start"
              render={(obj) => (
                <div className="flex flex-col ">
                  <b>{obj.title}</b>
                  <p>{obj.description}</p>
                </div>
              )}
              value={selectedOption}
              onChange={(val) => setSelectedOption(val)}
            />
  
            <Button1 onClick={handleOption}>Confirm</Button1>
          </div>
        </Modal1>
  
        <Modal1 open={openPDFExport} setOpen={setOpenPDFExport}>
          {/* <ReactToPrint content={() => componentRef}>
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <button onClick={handlePrint}>Print this out!</button>
                )}
            </PrintContextConsumer> */}
            <div ref={componentRef}>
              <FlightDoc data={order} />
            </div>
          {/* </ReactToPrint> */}
        </Modal1>
  
        <Modal3 open={openCancelOrder} setOpen={setOpenCancelOrder} title='Cancel Order'>
          <div className="card p-10 flex flex-col gap-4">
            <h5>Are you sure you want to cancel this order?</h5>
            <div className="flex gap-4 items-center">
              <div>
                <Button1 variant='outlined' className='whitespace-nowrap' onClick={() => setOpenCancelOrder(false)}>No, Close</Button1>
              </div>
              <Button1 loading={loadings?.cancel} onClick={() => handleCancelAndRefund()}>Yes, Cancel and Refund</Button1>
            </div>
          </div>
        </Modal3>
  
        <Modal3 open={openCancelRequest} setOpen={setOpenCancelRequest} title='Cancel Request'>
          <div className="card p-10 flex flex-col gap-4">
            <h5>Are you sure you want to cancel this request?</h5>
            <div className="flex gap-4 items-center">
              <div>
                <Button1 variant='outlined' className='whitespace-nowrap' onClick={() => setOpenCancelRequest(false)}>No, Close</Button1>
              </div>
              <Button1 loading={loadings?.cancel} onClick={() => handleCancel()}>Yes, Cancel Request</Button1>
            </div>
          </div>
        </Modal3>
  
      </div>
    );
  };
  
  function BookInfo({flightBooking,page,order,bookingData}) {
    const requestPage = page;
    const data = page === 'Ticket' ? [
      {label: 'Original Order ID',value: <Link className="underline text-blue-500" to={'/order/flight/ticket/detail/'+bookingData?._id}>{flightBooking?.bookingId}</Link>},
      {label: 'Order date',value: moment(flightBooking?.createdAt)?.format('DD/MM/YYYY HH:mm A')},
      {label: 'PNR',value: flightBooking?.pnr,lgScreen: true},
    ] : requestPage ? [
      {label: 'Original Order ID',value: <Link className="underline text-blue-500" to={'/order/flight/ticket/detail/'+bookingData?._id}>{flightBooking?.bookingId}</Link>},
      {label: 'Change Order ID',value: order?.orderId},
      {label: 'Order date',value: moment(flightBooking?.createdAt)?.format('DD/MM/YYYY HH:mm A')},
      {label: 'PNR',value: flightBooking?.pnr,lgScreen: true},
    ] : 
    [
      {label: 'Booking ID',value: <label className="text-blue-500">{flightBooking?.bookingId}</label>},
      {label: 'Order date',value: moment(flightBooking?.createdAt)?.format('DD/MM/YYYY HH:mm A')},
      {label: 'PNR',value: flightBooking?.pnr,lgScreen: true},
      // {label: 'Airline PNR',value: ""},
    ]
    return (
      <div className="p-5 border-t-4 bg-theme1/5 border-t-theme1 flex flex-col sm:flex-row gap-6 overflow-x-auto">
        {data?.map((obj,i) => (
            <div className={`flex flex-col ${obj?.lgScreen ? 'hidden':''}`} key={i}>
              <p>{obj?.label}</p>
              <div>{obj?.value}</div>
            </div>
        ))}
      </div>
    )
  }

  const Classname = {
    button:
      "h-10 lg:h-12 px-2 lg:px-4 rounded-md border-2 border-gray-300 font-bold mr-3 text-sm lg:text-base",
  };
  