import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setConfirmFlightSubmitRefundRequestModal,
  setConfirmTourBookingModal,
  setTicketManagementLoadingModal,
} from "../../redux/reducers/modalSlice";
import { useState } from "react";
import { Checkbox, Radio } from "@mui/material";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 800 ? 800 : "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function ConfirmFlightSubmitRefundRequestModal({confirmed, setConfirmed, callback}) {
  const dispatch = useDispatch();
  const { confirmFlightSubmitRefundRequestModal } = useSelector(
    (state) => state.modal
  );

  const handleOpen = () => dispatch(setConfirmTourBookingModal(true));
  const handleClose = () =>
    dispatch(setConfirmFlightSubmitRefundRequestModal(false));

  return (
    <div>
      <Modal
        open={confirmFlightSubmitRefundRequestModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className={Classname.content}>
              <span className={Classname.modalTitle}>Confirm change</span>
              <AirlineDetail />
              <Compare />
              <CTASection {...{callback, confirmed, setConfirmed}} />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const AirlineDetail = () => {
  return (
    <div className="w-full flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-10 lg:px-10 mb-4 border-b pb-2">
      <div className="flex litems-center justify-between lg:justify-start w-full lg:w-fit gap-2 lg:gap-6">
        <div className="flex flex-row lg:flex-col items-center gap-2">
          <img
            src="/IconTurkish.svg"
            alt=""
            className="w-6 lg:w-12 h-6 lg:h-12 object-contain"
          />
          <span className="font-bold text-sm">Turkish airline</span>
        </div>
        <span className="font-bold text-base lg:text-lg">Lagos — London</span>
      </div>
      <div className="flex flex-col text-sm lg:text-base">
        <span>
          <span className="bg-[#FDF3D7]">11:50pm</span> -{" "}
          <span className="bg-[#FDF3D7]">9:20pm</span>{" "}
          <span className="bg-[#FDF3D7]">(12hours 14mins, 2 stops)</span>
        </span>
        <span>LOS - LHR</span>
      </div>
    </div>
  );
};

const Compare = () => {
  const passengers = [
    { name: "Chinedu John Ike", ageBand: "Adult", gender: "Male" },
  ];
  return (
    <div className="flex flex-row lg:flex-col justify-between lg:justify-start w-full mb-4 gap-3">
      <div className="flex flex-col lg:w-full gap-3 lg:flex-row lg:items-center justify-start">
        <div className="bg-[#DBDBDB] rounded-md w-full lg:w-40 h-9 flex items-center justify-center font-bold text-sm">
          Flight
        </div>
        <span className="font-bold text-sm lg:text-base">
          Sat, 08 April 2024, <span className="bg-[#FDF3D7]">11:50pm</span>
        </span>
        <span className="font-bold text-sm lg:text-base">
          Cabin: <span className="text-gray-400">Economy</span>
        </span>
        <span className="font-bold text-sm lg:text-base">
          Booking Class: <span className="text-gray-400">T</span>
        </span>
      </div>
      <div className="flex flex-col lg:w-full gap-3 lg:flex-row  justify-start">
        <div className="bg-[#FDF3D7] rounded-md w-full lg:w-40 h-9 flex items-center justify-center font-bold text-sm">
          Passenger
        </div>
        <div className="flex flex-1 flex-col gap-2 mt-1">
          {passengers?.map((passenger) => (
            <div className="flex flex-col lg:w-full gap-3 lg:flex-row lg:items-center justify-start">
              <span className="font-bold text-sm lg:text-base">
                {passenger.name}
              </span>
              <span className="font-bold text-sm lg:text-base text-gray-400">
                {passenger.ageBand}
              </span>
              <span className="font-bold text-sm lg:text-base text-gray-400">
                {passenger.gender}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const CTASection = ({callback,confirmed,setConfirmed}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [check, setCheck] = useState(false);
  const [charge, setCharge] = useState(false);
  const [agree, setAgree] = useState(false);

  const handleGoBack = () => {
    dispatch(setConfirmFlightSubmitRefundRequestModal(false));
  };

  const handleConfirm = () => {
    dispatch(setConfirmFlightSubmitRefundRequestModal(false));
    dispatch(setTicketManagementLoadingModal(true));
    callback && callback()
    // setTimeout(() => {
    //   dispatch(setTicketManagementLoadingModal(false));
    //   navigate("/order/flight/ticket/confirmation?type=refund");
    // }, 2000);
  };

  return (
    <div className="w-full flex flex-col gap-4 py-4 lg:py-10 ">
      <label className="flex items-start gap-1 lg:gap-3">
        <Radio checked={confirmed} onClick={() => setConfirmed(true)} />
        <div className="flex flex-col">
          <span className="font-bold text-sm">
            Directly change (without refund charge check)
          </span>
          <span className="text-sm">
            If you choose to directly refund, regardless of weather it is
            refundable or not, the system will cancel the PNR automatically. If
            you want to know the refund amount, select check refund fee first
          </span>
        </div>
      </label>
      <label className="flex items-start gap-1 lg:gap-3">
        <Radio checked={!confirmed} onClick={() => setConfirmed(false)} />
        <div className="flex flex-col">
          <span className="font-bold text-sm">Check refund fee first</span>
          <span className="text-sm">
            If selected, we require a confirmation for you to continue. Please
            confirm refund fee as soon as possible after recieving a feedback
            from us or it will be cancelled automatically by our system when
            time expires
          </span>
        </div>
      </label>
      <div className="w-full flex items-center gap-10">
        <button
          onClick={handleGoBack}
          className="px-3 lg:px-10 font-bold flex items-center justify-center h-12"
        >
          Go Back
        </button>
        <button
          onClick={handleConfirm}
          className="lg:px-10 font-bold flex-1 flex items-center justify-center h-12 bg-primary1 rounded-md text-white"
        >
          Confirm Change
        </button>
      </div>
      <div className="flex w-full items-center justify-center gap-2">
        <Checkbox
          checked={agree}
          onClick={() => setAgree(!agree)}
          size="small"
        />
        <span className="text-xs lg:text-sm">
          I agree to Intraverse{" "}
          <a href="" className="text-primary1">
            Service Agreement, Terms & Conditions and Privacy Policy
          </a>
        </span>
      </div>
    </div>
  );
};

const Classname = {
  container: "flex flex-col font-main max-h-[95vh] overflow-scroll scroll-hide",
  content: "flex flex-col items-center px-4",
  modalTitle:
    "text-xl lg:text-3xl font-bold mb-6 py-4 border-b w-full text-center",
  modalDescription: "text-gray-600 mb-6 text-center text-sm lg:text-base",
  modalTourName: "font-bold flex flex-col items-center text-center",
  bottomContent:
    "w-full flex items-center justify-center gap-4 py-4 lg:py-10 bg-gray-100 px-4",
  button: "h-16 rounded-md flex items-center justify-center w-60 font-bold",
  backButton: "text-black",
  confirmButton: "text-white bg-primary1",
  wait: "font-bold",
};
