import React, { useEffect, useState } from 'react'
import LearnMoreButton from '../../components/mini/LearnMoreButton'
import CreateOrder from './CreateOrder'
import OrdersData from './OrdersData'
import { CircularProgress } from '@mui/material'
import getBookings from '../../controllers/booking/getBookings'
import { templateFlightOrderData, templateHotelOrderData, templateOrdersData, templateTourOrderData } from '../../data/order/ordersData'
import LearnAboutOrders from '../../components/DIsplay/LearnMore/LearnAboutOrders'
import Button1 from '../../components/form/Button1'
import { useHotels } from '../../hooks/useHotels'
import { useTours } from '../../hooks/useTours'
import { useDispatch } from 'react-redux'
import { setFlightOrders } from '../../redux/reducers/orderSlice'


const tempObj = {
  date: '22, Jan',name: 'John Doe',provider: 'gb Travels',type: ['Flight','Tour','Stay'][parseInt(Math.random()*3)],amount: 234900,
  commission: '4900',
  updatedDate: '5:30, 24/24/24',
  bookRef: parseInt(Math.random(99999)),
  status: ['confirmed','pending','on hold','cancelled','expired'][parseInt(Math.random(5))]
}

export default function Orders() {
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(false);
  const { getHotelBookings } = useHotels();
  const { getTourBookings } = useTours();
  
  const dispatch = useDispatch();

  useEffect(() => {
    loadData();
    //eslint-disable-next-line
  },[])

  const loadData = async () => {
    setLoading(true);
    let promises = [load(), loadTour(), loadHotel()];
    let responses = await Promise.all(promises);
    setLoading(false);
    responses = responses.flat();
    responses = responses.filter((response) => response);
    if (responses) {
      responses.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setData(responses);
    }
  };

  async function load() {
    const res = await getBookings();
    let data = [];
    if (res.return) {
      res.data?.data?.map((obj) => {
        obj?.flightBooking?.map((flightObj) =>
          data.push({ ...obj, flightObj, type: "Flight" })
        );
        return true;
      });
      dispatch(setFlightOrders(data))
      data = data?.map((obj) => templateFlightOrderData(obj));
    }
    return data;
  }

  const loadTour = async () => {
    let response = await getTourBookings();
    response = response?.map((obj) => templateTourOrderData(obj));
    return response;
  };

  const loadHotel = async () => {
    let response = await getHotelBookings();
    response = response?.map((obj) => templateHotelOrderData(obj));
    return response;
  };

  

  return loading ? (
    <div className='p-6 flex-1 flex justify-center items-center'>
      <CircularProgress />
    </div>
  ): !data?.length ? (
    <div className={`pd-md flex-1 flex flex-col ${!data?.length ? 'bg-emptypage':''}`}>
      <h5>Orders</h5>
      <div className='w-full sm:flex-1 py-10 sm:py-2 flex flex-col gap-4 h-full justify-center items-center'>
        <div>You don't have any orders</div>
        <div className='flex flex-col sm:flex-row gap-2 w-full sm:w-auto'>
          <LearnMoreButton label='Learn how to create order'
            component={<LearnAboutOrders callback={({setOpen}) => 
              <div className='flex justify-center py-4'>
                <Button1 className='!w-auto !px-10' onClick={() => setOpen && setOpen(false)}>Get Started</Button1>
              </div>
            }/>}
          />
          <CreateOrder handleReturn={() => setData([...data,tempObj])} />
        </div>
      </div>
    </div>


  ) : ( // data table list
    <OrdersData data={data} reload={loadData} setData={setData} />
  )
}
