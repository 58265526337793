export const CompareFlightChange = ({data}) => {
    console.log(data)
    const flightBooking = data?.oldOrder?.flightBooking?.at(0);
    const oldSegment = flightBooking?.flights?.find(obj => obj?.segmentRef === data?.query?.directionsToChange?.at(0))
    const newSegment = data?.flight?.newSegment?.at(0);
    const checkChange = (variant) => {
      switch(variant) {
        case 'time':
          return oldSegment?.departureTime !== newSegment?.departure?.time
        case 'date':
          return oldSegment?.departureDate !== newSegment?.departure?.date
        default: return false
      }
    }
    return (
      <div className="flex flex-row lg:flex-col justify-between lg:justify-start w-full mb-4 gap-3">
        <div className="flex gap-4 items-center flex-wrap">
          <img src={newSegment?.airline?.image?.url} alt='' className="w-[100px] object-cover " />
          <div className="flex gap-4 items-center justify-center flex-1 font-extrabold">
            {flightBooking?.flights?.map((obj,i) => (
              <div key={i} className="flex items-center gap-4">
                {obj?.departureLocationName}
                {i !== (flightBooking?.flights?.length-1) ? 
                  <hr className="w-6 border-primary" />
                :null}
              </div>
            ))}
          </div>
          <div>
            <div>
              {flightBooking?.flights?.at(0)?.departureTime} - {flightBooking?.flights?.at(0)?.arrivalTime}
              &nbsp;
              ({flightBooking?.flights?.at(0)?.duration}, {flightBooking?.flights?.at(0)?.stops || 0} stops)
            </div>
            <div className="flex gap-4 items-center">
              {flightBooking?.flights?.map((obj,i) => (
                <div key={i} className="flex items-center gap-4">
                  {obj?.departureLocation}
                  {i !== (flightBooking?.flights?.length-1) ? 
                    <hr className="w-6 border-primary" />
                  :null}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:w-full gap-3 lg:flex-row lg:items-center justify-start">
          <div className="bg-[#DBDBDB] rounded-md w-full lg:w-40 h-9 flex items-center justify-center font-bold text-sm">
            Previous details
          </div>
          <span className="font-bold text-sm lg:text-base flex gap-1">
            <span className={`${checkChange('date') ? 'bg-[#FDF3D7]' : ''}`}>{oldSegment?.departureDate}</span>
            <span className={`${checkChange('time') ? 'bg-[#FDF3D7]' : ''}`}>{oldSegment?.departureTime}</span>
          </span>
          <span className="font-bold text-sm lg:text-base">
            Cabin: <span className="text-gray-300">{oldSegment?.cabinClass}</span>
          </span>
          <span className="font-bold text-sm lg:text-base">
            Booking Class: <span className="text-gray-300">{oldSegment?.bookingClass}</span>
          </span>
        </div>
        <div className="flex flex-col lg:w-full gap-3 lg:flex-row lg:items-center justify-start">
          <div className="bg-[#FDF3D7] rounded-md w-full lg:w-40 h-9 flex items-center justify-center font-bold text-sm">
            New details
          </div>
          <span className="font-bold text-sm lg:text-base flex gap-1">
            <span className={`${checkChange('date') ? 'bg-[#FDF3D7]' : ''}`}>{newSegment?.departure?.date}</span>
            <span className={`${checkChange('time') ? 'bg-[#FDF3D7]' : ''}`}>{newSegment?.departure?.time}</span>
          </span>
          <span className="font-bold text-sm lg:text-base">
            Cabin: <span className="text-gray-300">{newSegment?.cabinClass || oldSegment?.cabinClass}</span>
          </span>
          <span className="font-bold text-sm lg:text-base">
            Booking Class: <span className="text-gray-300">{newSegment?.bookingClass || oldSegment?.bookingClass}</span>
          </span>
        </div>
      </div>
    );
  };