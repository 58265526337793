import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CalendarInput1 from "../../../components/form/CalendarInput1";
import TicketManagementHeader from "../../../components/TicketManagement/TicketManagementHeader";
import TicketManagementFlightChangeInfoStepsHeader from "../../../components/TicketManagement/TicketManagementFlightChangeInfoStepsHeader";
import ConfirmFlightNameChangeModal from "../../../components/modal/ConfirmFlightNameChangeModal";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmFlightNameChangeModal } from "../../../redux/reducers/modalSlice";
import TicketManagementLoadingModal from "../../../components/modal/TicketManagementLoadingModal";
import TicketManagementPassengerSelection from "../../../components/TicketManagement/TicketManagementPassengerSelection";
import TicketManagementUploadDocument from "../../../components/TicketManagement/TicketManagementUploadDocument";
import TicketManagementContactDetails from "../../../components/TicketManagement/TicketManagementContactDetails";
import TicketManagementAddRemark from "../../../components/TicketManagement/TicketManagementAddRemark";
import { useNavigate, useParams } from "react-router-dom";
import getBooking from "../../../controllers/booking/getBooking";
import CountriesInput from "../../../components/form/CountriesInput";
import requestFlightChange from "../../../controllers/booking/postTicketing/change/requestFlightChange";
import { enqueueSnackbar } from "notistack";

function TicketManagementFlightChangeInfo() {
  const {id} = useParams()
  const [order, setOrder] = useState();
  const [changeList,setChangeList] = useState([]);

  const {user} = useSelector(state => state.user.userData);
  const userName = (user?.firstName||'') + ' ' + (user?.middleName||'') + ' ' + (user?.lastName||'');

  const [contact,setContact] = useState({
    name: userName || '',
    email: user?.email || '',
    phone: user?.phone || '',    
  })
  const [remark,setRemark] = useState('');
  const [documents,setDocuments] = useState([]);
  const [passengers,setPassengers] = useState([]);


  const [loading,setLoading] = useState({submit: false, fetching: true, confirmFee: false});

  const navigate = useNavigate();
  
  
  useEffect(() => {
    fetch();
    //eslint-disable-next-line
  }, [id]);

  const fetch = async () => {
    if(!id) return setLoading({...loading,fetching: false});

    setLoading({...loading,fetching: true});
    const res = await getBooking(id);
    setLoading({...loading,fetching: false});
    if (res.return) {
      setOrder(res.data);
    }
  };
  const bookingData = order?.booking?.flightBooking?.at(0);

  async function handleSubmit(params) {
    const payload = {
      "flightBookingId": bookingData?._id,
      "changeRequest": "PassengerInfo", //Flight , PassengerInfo
      "adult": bookingData?.adult?.passengerCount || 0,
      "child": bookingData?.child?.passengerCount || 0,
      "infant": bookingData?.infant?.passengerCount || 0,
      "directionsToChange": bookingData?.flights?.map(obj => obj?._id),
      relatedDocs: documents,
      // "newDirections": bookingData?.destinations?.map(obj => ({
      //   from: obj?.from,
      //   to: obj?.to,
      //   departure: {
      //     date: obj?.departureDate
      //   },
      //   journeyType: bookingData?.destinations?.at(0)?.from === obj?.to ? 'Inbound':'Outbound'
      // })),
      "remark": remark,
      "reason": "Voluntary",
      "directChange": true,
      "passengerInfo": passengers?.map(obj => {
        const {phone,...PAX} = obj;
        return {
          ...PAX,
          middleName: obj?.middleName || undefined
        }
      }),
      // [
      //     {
      //         "id": "1",
      //         "lastName": "Doe",
      //         "firstName": "John",
      //         "middleName": "Oliver",
      //         "birthDate": "1985-08-20",
      //         "gender": "Male",
      //         "type": "ADT", //ADT, CNN, INF, CHD
      //         "phone": [
      //             {
      //                 "countryCode": "+1",
      //                 "location": "NY",
      //                 "number": "555001234"
      //             }
      //         ],
      //         "email": "john.doe@example.com",
      //         "document": {
      //             "documentType": "Passport",
      //             "birthPlace": "NG",
      //             "issuanceLocation": "NG",
      //             "issuanceDate": "2015-05-10",
      //             "number": "123456789",
      //             "expiryDate": "2025-05-10",
      //             "issuanceCountry": "NG",
      //             "validityCountry": "NG",
      //             "nationality": "NG"
      //         }
      //     }
      // ],
      "contactDetails": contact,
    }
    // return console.log(payload)
    setLoading({...loading,submit: true})
    const res = await requestFlightChange(payload)
    setLoading({...loading,submit: false})
    if(res.return) {
      enqueueSnackbar('Order change has been requested.',{variant: 'success'})
      navigate('/order/flight/ticket/detail/'+id+'?page=PAX Change')
      // callback && callback()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }

  
  return (
    <div className="flex flex-1 flex-col w-full bg-[#F8F8F8] py-4">
      <ConfirmFlightNameChangeModal callback={handleSubmit} />
      <TicketManagementLoadingModal {...{open:loading.submit,setOpen: (val) => setLoading({...loading,submit: val})}}/>

      <TicketManagementHeader />
      <TicketManagementFlightChangeInfoStepsHeader step={1} />
      <TicketManagementPassengerSelection order={order} callback={(passengers) => setPassengers(passengers)} />
      <SelectContentChange callback={setChangeList} />
      <PassengerDetails {...{changeList,order,passengers,setPassengers}} />
      <TicketManagementUploadDocument uploader callback={(docs) => setDocuments(docs)} />
      <TicketManagementAddRemark callback={setRemark} />
      <TicketManagementContactDetails order={order} data={contact} callback={(obj) => setContact({...contact,...obj})} />
      <TicketActionButtons order={order} passengers={passengers}/>
    </div>
  );
}

const SelectContentChange = ({callback}) => {
  const [contentChange, setContentChange] = useState([""]);
  const [lock,setLock] = useState(true);

  useEffect(() => {
    if(!lock)
      callback && callback(contentChange)
    else setLock(false);
    //eslint-disable-next-line
  },[contentChange])

  const contentChanges = [
    "Passenger Name",
    "Gender",
    "date of birth",
    "Change ID / Passport details",
  ];

  return (
    <div className={Classname.cardContainer}>
      <span className="font-bold text-black">
        Select content change <span className="font-normal">(Required)</span>
      </span>
      <div className={Classname.detailInfoContainer}>
        <img src="/IconInfo.svg" alt="" className={Classname.detailInfoIcon} />
        <span className="font-normal">
          There are two types of changes: Voluntary and Involuntary changes.
          Voluntary changes refer to changes that are made in accordance to the
          fare rules set by airlines. These include modifications that are
          allowed within the allowed guideline. Any other change is considered
          involuntary. Involuntary changes require a document to support
          contentChange for change. The document would serve as an evidence for
          contentChanges behind the change and help ensure a smooth processing.
        </span>
      </div>
      <div className="max-w-sm">
        <FormControl fullWidth>
          <Select multiple
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={contentChange}
            onChange={(e) => setContentChange(e?.target?.value)}
            displayEmpty
          >
            <MenuItem value='' disabled>
              Select detail to change (Multiple options allowed)
            </MenuItem>
            {contentChanges.map((r, index) => (
              <MenuItem key={index} value={r}>
                {r}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

const peopleDetails = [
  {
    title: "Mr",
    firstName: "John",
    middleName: "david",
    lastName: "davidson",
    gender: "Male",
    dob: "2000-04-04",
    nationality: "Nigeria",
    passport: "1232323232",
    expiryDate: "2025-04-04",
  },
];

const PassengerDetails = ({changeList,passengers,setPassengers}) => {
  // const [passengers, setPassengers] = useState([]);
  const dobRef = useRef([...Array(passengers.length)]);
  const expiryRef = useRef([...Array(passengers.length)]);

  const titles = ["Mr", "Mrs"];
  const genders = ["Male", "Female"];
  const countries = ["United States", "Canada", "Nigeria", "UK"];

  return (
    <div className={Classname.cardContainer}>
      <span className="font-bold text-black">
        Passenger details <span className="font-normal">(Required)</span>
      </span>
      <div className={Classname.detailInfoContainer}>
        <img src="/IconInfo.svg" alt="" className={Classname.detailInfoIcon} />
        <span className="font-normal">
          Use all given names and surnames exactly as they appear in your
          passport/ID to avoid boarding complications
        </span>
      </div>
      {(passengers||[]).map((passenger, index) => (
        <div
          className="w-full flex flex-col text-[#818083] font-bold text-sm"
          key={index}
        >
          <span className="mt-2 mb-2 text-sm text-gray-400">
            Passenger {index + 1}
          </span>
          {/* <div className={`${changeList?.includes('Passenger Name') ? '':'hidden'} gap-1 flex flex-col w-24`}>
            <span>Title</span>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={passenger?.title || ''}
                placeholder="Select cabin"
                className="h-10"
                onChange={(e) => {
                  setPassengers(
                    passengers.map((p, idx) =>
                      idx === index ? { ...p, title: e.target.value } : p
                    )
                  );
                }}
              >
                {titles.map((title, idx) => (
                  <MenuItem value={title} key={idx}>
                    {title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div> */}
          <div className="flex flex-col lg:flex-row gap-2 w-full items-center mt-2">
            <div className={`${changeList?.includes('Passenger Name') ? '':'hidden'} gap-1 flex flex-col flex-1 w-full lg:w-fit`}>
              <span>First name</span>
              <input
                type="text"
                placeholder="Contact name"
                className={Classname.input}
                value={passenger?.firstName}
                onChange={(e) => {
                  setPassengers(
                    passengers.map((p, idx) =>
                      idx === index ? { ...p, firstName: e.target.value } : p
                    )
                  );
                }}
              />
            </div>
            <div className={`${changeList?.includes('Passenger Name') ? '':'hidden'} gap-1 flex flex-col flex-1 w-full lg:w-fit`}>
              <span>Middle name</span>
              <input
                type="text"
                placeholder="Contact name"
                className={Classname.input}
                value={passenger?.middleName}
                onChange={(e) => {
                  setPassengers(
                    passengers.map((p, idx) =>
                      idx === index ? { ...p, middleName: e.target.value } : p
                    )
                  );
                }}
              />
            </div>
            <div className={`${changeList?.includes('Passenger Name') ? '':'hidden'} gap-1 flex flex-col flex-1 w-full lg:w-fit`}>
              <span>Last name</span>
              <input
                type="text"
                placeholder="Contact name"
                className={Classname.input}
                value={passenger?.lastName}
                onChange={(e) => {
                  setPassengers(
                    passengers.map((p, idx) =>
                      idx === index ? { ...p, lastName: e.target.value } : p
                    )
                  );
                }}
              />
            </div>
          </div>
          <div className="flex gap-2 w-full items-center mt-2">
            <div className={`${changeList?.includes('Gender') ? '':'hidden'} gap-1 flex flex-col flex-1`}>
              <span>Gender</span>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={passenger?.gender}
                  placeholder="Select cabin"
                  className="h-10"
                  onChange={(e) => {
                    setPassengers(
                      passengers.map((p, idx) =>
                        idx === index ? { ...p, gender: e.target.value } : p
                      )
                    );
                  }}
                >
                  {genders.map((gender, idx) => (
                    <MenuItem value={gender} key={idx}>
                      {gender}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={`${changeList?.includes('date of birth') ? '':'hidden'} gap-1 flex flex-col flex-1`}>
              <span>Date of birth</span>
              <CalendarInput1
                ref={(el) => (dobRef.current[index] = el)}
                className="w-full border border-primary/20 rounded-md p-2"
                value={passenger.birthDate || ""}
                onChange={(value) => {
                  dobRef.current[index]?.toggle();

                  setPassengers(
                    passengers.map((p, idx) =>
                      idx === index ? { ...p, birthDate: value?.start || value } : p
                    )
                  );
                }}
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-2 w-full items-center mt-2">
            <div className={`${changeList?.includes('Change ID / Passport details') ? '':'hidden'} gap-1 flex flex-col flex-1 w-full lg:w-fit`}>
              <span>Nationality</span>
              <CountriesInput label={' '}
                value={passenger?.document?.nationality}
                onChange={(val) => {
                  setPassengers(
                    passengers.map((p, idx) =>
                      idx === index
                        ? { ...p, document: {...(p.document||{}),nationality: val?.alpha2 || val} }
                        : p
                    )
                  );
                }}
                // onChange={(val) => handleData({...data,document:{...data.document,nationality: val?.alpha2 || val}})}
              />

              {/* <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={passenger?.document?.nationality}
                  placeholder="Select cabin"
                  className="h-10"
                  onChange={(e) => {
                    setPassengers(
                      passengers.map((p, idx) =>
                        idx === index
                          ? { ...p, document: {...(p.document||{}),nationality: e.target.value} }
                          : p
                      )
                    );
                  }}
                >
                  {countries.map((country, idx) => (
                    <MenuItem value={country} key={idx}>
                      {country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </div>
            <div className={`${changeList?.includes('Change ID / Passport details') ? '':'hidden'} gap-1 flex flex-col flex-1 w-full lg:w-fit`}>
              <span>Passport</span>
              <input
                type="text"
                placeholder="ID / Passport Number"
                className={Classname.input}
                value={passenger?.document?.number}
                onChange={(e) => {
                  setPassengers(
                    passengers.map((p, idx) =>
                      idx === index ? { ...p, document: {...(p.document||{}),number: e.target.value} } : p
                    )
                  );
                }}
              />
            </div>
            <div className={`${changeList?.includes('Change ID / Passport details') ? '':'hidden'} gap-1 flex flex-col flex-1 w-full lg:w-fit`}>
              <span>Expiry date</span>
              <CalendarInput1
                ref={(el) => (expiryRef.current[index] = el)}
                className="w-full border border-primary/20 rounded-md p-2"
                value={passenger?.document?.expiryDate || ""}
                onChange={(value) => {
                  expiryRef.current[index]?.toggle();

                  setPassengers(
                    passengers.map((p, idx) =>
                      idx === index
                        ? { ...p, document: {...(p.document||{}),expiryDate: value?.start || value} }
                        : p
                    )
                  );
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const TicketActionButtons = ({passengers,order}) => {
  const orderData = order?.booking?.flightBooking?.at(0);
  const dispatch = useDispatch();
  const handleCancel = () => {};

  const handleSubmit = () => {
    dispatch(setConfirmFlightNameChangeModal({passengers,oldPassengersData:orderData?.travelers}));
  };
  return (
    <div className="flex justify-between gap-4 mb-20 mt-10 px-2">
      <button
        onClick={handleCancel}
        className="px-3 py-3 font-bold w-[30%] max-w-[200px] bg-gray-100 rounded-md"
      >
        Cancel
      </button>
      <button
        onClick={handleSubmit}
        className="flex flex-1 text-sm font-bold text-white bg-primary1 rounded-md py-3 items-center justify-center max-w-[250px]"
      >
        Submit request
      </button>
    </div>
  );
};

export default TicketManagementFlightChangeInfo;

const Classname = {
  detailInfoContainer:
    "w-full bg-blue-500/10 flex items-start lg:items-center gap-2 mb-4 px-2 lg:px-6 py-2 rounded-md text-sm mt-6",
  detailInfoIcon: "",
  cardContainer:
    "flex flex-1 flex-col w-full p-2 lg:p-4 bg-white mt-6 rounded-md",
  input: "flex flex-1 py-3 rounded-md px-4 border-2 border-gray-200",
};
