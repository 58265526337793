export const ComparePAX = ({data: {previous,newData}}) => {
    const details = {
      previous: [
        { title: "First name", value: previous?.firstName },
        { title: "Middle name", value: previous?.middleName },
        { title: "Last name", value: previous?.lastName },
        { title: "Gender", value: previous?.gender },
        { title: "Birth date", value: previous?.birthDate },
        { title: "Nationality", value: previous?.document?.nationality },
        { title: "Passsport/ID", value: previous?.document?.number },
        { title: "Passport/ID Expiry Date", value: previous?.document?.expiryDate },
      ],
      new: [
        { title: "First name", value: newData?.firstName, change: true },
        { title: "Middle name", value: newData?.middleName, change: true },
        { title: "Last name", value: newData?.lastName},
        { title: "Gender", value: newData?.gender },
        { title: "Birth date", value: newData?.birthDate },
        { title: "Nationality", value: newData?.document?.nationality },
        { title: "Passsport/ID", value: newData?.document?.number },
        { title: "Passport/ID Expiry Date", value: newData?.document?.expiryDate },
      ],
    };
  
    details?.new?.map((obj,i) => (
      obj.change = obj.value !== details?.previous?.at(i)?.value
    ))
    
    return (
      <div>
      <div className='flex justify-center'>
        <h5>Changed Passenger Details</h5>
      </div>
      <div className="flex flex-row lg:flex-col justify-between lg:justify-start w-full mb-4 gap-3 overflow-x-auto ">
        <div className="flex flex-col lg:w-full gap-3 lg:flex-row items-center">
          <div className="flex flex-col justify-center bg-secondary sticky left-0 pr-2">
            <div className="bg-[#DBDBDB] text-center whitespace-nowrap rounded-md min-w-[200px] h-9 flex items-center justify-center font-bold text-sm">
              Previous details
            </div>
          </div>
          {details.previous.map((item, index) => (
            <span className="text-sm lg:text-sm min-w-[100px]" key={index}>
              {item?.title}:{" "}
              <span
                className={item?.change ? "bg-[#FDF3D7] font-bold" : "font-bold"}
              >
                {item.value}
              </span>
            </span>
          ))}
        </div>
        <div className="flex flex-col lg:w-full gap-3 lg:flex-row items-center">
          <div className="flex flex-col justify-center bg-secondary sticky left-0 pr-2">
            <div className="bg-[#FDF3D7] text-center whitespace-nowrap rounded-md min-w-[200px] h-9 flex items-center justify-center font-bold text-sm">
              New details
            </div>
          </div>
          {details?.new?.map((item, index) => (
            <span className="text-sm lg:text-sm min-w-[100px]" key={index}>
              {item?.title}:{" "}
              <span
                className={item?.change ? "bg-[#FDF3D7] font-bold" : "font-bold"}
              >
                {item.value}
              </span>
            </span>
          ))}
        </div>
      </div>
      </div>
    );
  };
  