import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setConfirmFlightDateChangeModal,
  setConfirmTourBookingModal,
  setTicketManagementLoadingModal,
} from "../../redux/reducers/modalSlice";
import { useState } from "react";
import { Checkbox, Radio } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import requestFlightChange from "../../controllers/booking/postTicketing/change/requestFlightChange";
import { enqueueSnackbar } from "notistack";
import selectFlightChange from "../../controllers/booking/postTicketing/change/selectFlightChange";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 800 ? 800 : "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function ConfirmFlightDateChangeModal() {
  const {id} = useParams();
  const dispatch = useDispatch();
  const { confirmFlightDateChangeModal } = useSelector((state) => state.modal);
  const [loading,setLoading] = useState({submit: false});

  const navigate = useNavigate();
  
  const handleOpen = () => dispatch(setConfirmTourBookingModal(true));
  const handleClose = () => dispatch(setConfirmFlightDateChangeModal(false));

  async function handleSubmit(obj) {
    setLoading({...loading,submit: true})
    const res = await selectFlightChange({
      reshopOffer: [confirmFlightDateChangeModal?.flight?.og]
      // ...confirmFlightDateChangeModal?.query,
      // ...(obj || {})
    })
    setLoading({...loading,submit: false})
    if(res.return) {
      console.log(res?.data)
      enqueueSnackbar('Order change has been requested.',{variant: 'success'})
      navigate('/order/flight/ticket/detail/'+(res?.data?._id || id)+'?page=Re-issue Management')
      // callback && callback()
    }
  }

  
  return (
    <div>
      <Modal
        open={confirmFlightDateChangeModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className={Classname.content}>
              <span className={Classname.modalTitle}>Confirm change</span>
              <AirlineDetail data={confirmFlightDateChangeModal} />
              <Compare data={confirmFlightDateChangeModal} />
              <CTASection callback={(obj) => handleSubmit(obj)} />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const AirlineDetail = ({data}) => {
  const flightBooking = data?.oldOrder?.booking?.flightBooking?.at(0);
  const oldSegment = flightBooking?.flights?.find(obj => obj?.segmentRef === data?.query?.directionsToChange?.at(0))

  const checkChange = (variant) => {
    switch(variant) {
      case 'time':
        return oldSegment?.departureTime !== data?.flight?.og?.departure?.time
      default: return false
    }
  }

  return (
    <div className="w-full flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-10 lg:px-10 mb-4">
      <div className="flex litems-center justify-between lg:justify-start w-full lg:w-fit gap-2 lg:gap-6">
        <div className="flex flex-row lg:flex-col items-center gap-2">
          <img
            src={oldSegment?.airlineImage}
            alt=""
            className="w-6 lg:w-12 h-6 lg:h-12 object-contain"
          />
          <span className="font-bold text-sm">{oldSegment?.airlineName}</span>
        </div>
        <span className="font-bold text-base lg:text-lg">{oldSegment?.departureLocationName} — {oldSegment?.arrivalLocationName}</span>
      </div>
      <div className="flex flex-col text-sm lg:text-base">
        <span>
          <span className={`${checkChange('time') ? 'bg-[#FDF3D7]' : ''}`}>{oldSegment?.departureTime}</span> -{" "}
          <span className={`${checkChange('time') ? 'bg-[#FDF3D7]' : ''}`}>{oldSegment?.arrivalTime}</span>{" "}
          <span className={`${checkChange('time') ? 'bg-[#FDF3D7]' : ''}`}>({oldSegment?.duration}, {oldSegment?.stops} stops)</span>
        </span>
        <span>{oldSegment?.departureLocation} - {oldSegment?.arrivalLocation}</span>
      </div>
    </div>
  );
};

const Compare = ({data}) => {
  console.log(data)
  const flightBooking = data?.oldOrder?.booking?.flightBooking?.at(0);
  const oldSegment = flightBooking?.flights?.find(obj => obj?.segmentRef === data?.query?.directionsToChange?.at(0))
  const checkChange = (variant) => {
    switch(variant) {
      case 'time':
        return oldSegment?.departureTime !== data?.flight?.og?.departure?.time
      default: return false
    }
  }
  return (
    <div className="flex flex-row lg:flex-col justify-between lg:justify-start w-full mb-4 gap-3">
      <div className="flex flex-col lg:w-full gap-3 lg:flex-row lg:items-center justify-start">
        <div className="bg-[#DBDBDB] rounded-md w-full lg:w-40 h-9 flex items-center justify-center font-bold text-sm">
          Previous details
        </div>
        <span className="font-bold text-sm lg:text-base">
          {oldSegment?.departureDate} <span className={`${checkChange('time') ? 'bg-[#FDF3D7]' : ''}`}>{oldSegment?.departureTime}</span>
        </span>
        <span className="font-bold text-sm lg:text-base">
          Cabin: <span className="text-gray-300">{oldSegment?.cabinClass}</span>
        </span>
        <span className="font-bold text-sm lg:text-base">
          Booking Class: <span className="text-gray-300">{oldSegment?.bookingClass}</span>
        </span>
      </div>
      <div className="flex flex-col lg:w-full gap-3 lg:flex-row lg:items-center justify-start">
        <div className="bg-[#FDF3D7] rounded-md w-full lg:w-40 h-9 flex items-center justify-center font-bold text-sm">
          New details
        </div>
        <span className="font-bold text-sm lg:text-base">
          {data?.flight?.og?.departure?.date} <span className={`${checkChange('time') ? 'bg-[#FDF3D7]' : ''}`}>{data?.flight?.og?.departure?.time}</span>
        </span>
        <span className="font-bold text-sm lg:text-base">
          Cabin: <span className="text-gray-300">Economy</span>
        </span>
        <span className="font-bold text-sm lg:text-base">
          Booking Class: <span className="text-gray-300">T</span>
        </span>
      </div>
    </div>
  );
};

const CTASection = ({callback}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [change, setChange] = useState(false);
  const [agree, setAgree] = useState(false);

  const handleGoBack = () => {
    dispatch(setConfirmFlightDateChangeModal(false));
  };

  const handleConfirm = () => {
    dispatch(setConfirmFlightDateChangeModal(false));
    dispatch(setTicketManagementLoadingModal(true));
    callback && callback({directChange: change})

  };

  setTimeout(() => {
    dispatch(setTicketManagementLoadingModal(false));
    // navigate("/order/flight/ticket/confirmation?type=dateChange");
  }, 2000);

  return (
    <div className="w-full flex flex-col gap-4 py-4 lg:py-10 ">
      <div className="flex items-start gap-1 lg:gap-3">
        <Radio checked={change} onClick={() => setChange(!change)} />
        <div className="flex flex-col">
          <span className="font-bold text-sm">
            Directly change if it's free
          </span>
          <span className="text-sm">
            If selected we will proceed with change request with airline if
            there is no fee to pay airline
          </span>
        </div>
      </div>
      <div className="w-full flex items-center gap-10">
        <button
          onClick={handleGoBack}
          className="px-3 lg:px-10 font-bold flex items-center justify-center h-12"
        >
          Go Back
        </button>
        <button
          onClick={handleConfirm}
          className="lg:px-10 font-bold flex-1 flex items-center justify-center h-12 bg-primary1 rounded-md text-white"
        >
          Confirm Change
        </button>
      </div>
      <div className="flex w-full items-center justify-center gap-2">
        <Checkbox
          checked={agree}
          onClick={() => setAgree(!agree)}
          size="small"
        />
        <span className="text-xs lg:text-sm">
          I agree to Intraverse{" "}
          <a href="" className="text-primary1">
            Service Agreement, Terms & Conditions and Privacy Policy
          </a>
        </span>
      </div>
    </div>
  );
};

const Classname = {
  container: "flex flex-col font-main",
  content: "flex flex-col items-center px-4",
  modalTitle:
    "text-xl lg:text-3xl font-bold mb-6 py-4 border-b w-full text-center",
  modalDescription: "text-gray-600 mb-6 text-center text-sm lg:text-base",
  modalTourName: "font-bold flex flex-col items-center text-center",
  bottomContent:
    "w-full flex items-center justify-center gap-4 py-4 lg:py-10 bg-gray-100 px-4",
  button: "h-16 rounded-md flex items-center justify-center w-60 font-bold",
  backButton: "text-black",
  confirmButton: "text-white bg-primary1",
  wait: "font-bold",
};
